import Component from 'components/component'
import { livesView } from 'config/routes'

export class LivesItemBase extends Component {
  get live() {
    return this.props.data || this.props.live
  }

  get image() {
    return this.live.image.v575x323.url
  }

  get link() {
    return document.location.origin + this.path
  }

  get path() {
    return livesView.getPath(this.props.common.locale, this.live.slug)
  }

  get preloaded() {
    const preloaded = this.props.lives.byID.getValue(this.live.slug).payload

    if (preloaded.id) {
      return preloaded
    } else {
      return this.live
    }
  }

  toggleFollowing = ev => {
    const live = this.preloaded
    this.props.actions.lives.toggleFollowing(this.live.slug, live.subscribed)
  }

  copyLink = () => {
    this.buffer.copy()
    this.props.actions.notifications.createNotification({
      type: 'notify',
      children: this.props.common.strings['after_copy_link'],
    })
  }

  state = {
    hover: false,
  }

  hover = hover => () => {
    if (!this.props.lives.byID[this.live.slug]) {
      this.props.actions.lives.loadLivePage(this.live.slug)
    }
  }
}
