import React from 'react'
import Component from 'components/component'
import { getCoords } from 'helpers/elements'

let prevScroll = 0

export default class InfinityFeed extends Component {
  static defaultProps = {
    element: 'div',
    enable: true,
    onEnd() {},
  }

  constructor(props) {
    super(props)

    this.wrapper = React.createRef()
  }

  componentDidMount() {
    prevScroll = getCoords(this.wrapper.current).scrollTop
    document.addEventListener('scroll', this.onScroll)
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.onScroll)
  }

  onScroll = () => {
    const el = this.wrapper.current
    const position = getCoords(el)
    const rect = el.getBoundingClientRect()

    if (
      this.props.enable &&
      prevScroll < position.scrollTop &&
      position.scrollTop + window.innerHeight > position.pageTop + rect.height
    ) {
      this.props.onEnd()
    }

    prevScroll = position.scrollTop
  }

  render() {
    const { element, handler, onEnd, enable, ...props } = this.props
    const Cmp = element
    return <Cmp ref={this.wrapper} {...props} />
  }
}
