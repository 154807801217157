import React from 'react'
// import DEPRECATED_moment from 'moment';
import Dropdown from 'components/dropdown'
import { DateTime } from 'luxon'

const startDate = DateTime.fromISO('2013-01-01')
const currentDate = DateTime.local()
const betweenDate = currentDate.diff(startDate, ['years'])
const betweenYears = Math.floor(betweenDate.years)

export default function DatepickerCaption({ date, localeUtils, onChange }) {
  date = DateTime.fromISO(date.toISOString())

  const month = React.useMemo(() => date.month, [date])
  const year = React.useMemo(() => date.year, [date])

  const months = React.useMemo(() => {
    return new Array(12).fill(null).map((item, index) => ({
      title: DateTime.local(currentDate.year, index + 1, 1, 0, 0).toFormat(
        'LLLL'
      ),
      value: index,
    }))
  }, [])

  const years = React.useMemo(
    () =>
      new Array(betweenYears + 6).fill(null).map((item, index) => ({
        title: startDate.year + index,
        value: startDate.year + index,
      })),
    []
  )

  const handleChange = field => (ev, value) => {
    const date = {
      year,
      month,
      [field]: value,
    }

    onChange(new Date(date.year, date.month))
  }

  return (
    <div className="DayPicker-Caption">
      <Dropdown
        items={months}
        modificator="date"
        headerModificator="date"
        value={month - 1}
        onChange={handleChange('month')}
      />
      <Dropdown
        items={years}
        modificator="date"
        headerModificator="date"
        value={year}
        onChange={handleChange('year')}
      />
    </div>
  )
}
