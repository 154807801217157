import React, { Fragment } from 'react'
import { DI } from 'modules'
import Component from 'components/component'

import Radio, { Radios } from 'components/form/radio'

export class FilterContentType extends Component {
  render() {
    const { strings } = this.props.common
    const { name, value } = this.props

    const types = [
      {
        title: strings['vods.filters.content_type.video'],
        value: 'vod',
      },
      {
        title: strings['vods.filters.content_type.live'],
        value: 'live',
      },
    ]

    return (
      <Fragment>
        <Radios>
          <Radio name={name} value="" checked={value === undefined}>
            {strings['vods.filters.any']}
          </Radio>
          {types.map(type => (
            <Radio
              key={type.value}
              name={name}
              value={type.value}
              checked={value === type.value}
            >
              {type.title}
            </Radio>
          ))}
        </Radios>
      </Fragment>
    )
  }
}

export default DI()(FilterContentType)
