import React from 'react'
import { DI } from 'modules'
import Component from 'components/component'

export class AEFilterPlain extends Component {
  selectViewType = type => ev => {
    ev.preventDefault()
    this.props.actions.common.setListViewType(type)
  }
  render() {
    return (
      <div className="ae-views">
        {this.props.common.listViewTypes.map(type => (
          <div key={type} className="ae-view">
            <a
              href="#"
              className={this.classList(
                `ae__view ae__view--${type}`,
                type === this.props.common.listViewType && '_active'
              )}
              onClick={this.selectViewType(type)}
            />
          </div>
        ))}
      </div>
    )
  }
}

export default DI(['common'])(AEFilterPlain)
