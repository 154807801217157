import React from 'react'
import { DI } from 'modules'

class GroupsComponent extends React.Component {
  render() {
    const grid = this.props.common.listViewType === 'grid'
    const classNamePrefix = grid ? 'aes' : 'laes'

    return <div className={classNamePrefix}>{this.props.children}</div>
  }
}

class GroupComponent extends React.Component {
  render() {
    const grid = this.props.common.listViewType === 'grid'
    const classNamePrefix = grid ? 'ae' : 'lae'

    return (
      <div className={`${classNamePrefix}`}>
        {this.props.title && (
          <div className={`${classNamePrefix}__title`}>{this.props.title}</div>
        )}
        {(this.props.subtitle || this.props.button) && (
          <div className={`${classNamePrefix}__subtitle`}>
            {this.props.subtitle}
            {this.props.button && (
              <a
                href="#"
                className={`btn-bord ${classNamePrefix}__unfollow-all`}
              >
                {this.props.button.text}
              </a>
            )}
          </div>
        )}
        {this.props.text && (
          <div className={`${classNamePrefix}__text`}>{this.props.text}</div>
        )}
        {this.props.children}
      </div>
    )
  }
}

export const Groups = DI()(GroupsComponent)
export const Group = DI()(GroupComponent)
