import React, { Fragment } from 'react'
import { DI } from 'modules'
import Component from 'components/component'
import { plural } from 'helpers/strings'

import PlanCardGroup, { PlanDetailsGroupParam } from '../plan-card-group'

import {
  Duration,
  Access,
  StartAt,
  ValidTill,
  ArchivalRights,
  EnterpriseBalanceItem,
  RegionUsageRights,
  BalanceSubtitle,
  TextWithTooltip,
} from './params'
import { contextType } from 'react-hammerjs'

export class EnterpriseDescription extends Component {
  static propTypes = {}

  static defaultProps = {}

  render() {
    const strings = this.props.common.strings
    const plan = this.props.data
    const company = this.props.user.company.payload
    const isMaster =
      this.props.user.currentUser.payload.user_group == 'customer'

    const balanceSubtitle = (
      <BalanceSubtitle
        show={plan.quota_period === 'month' && plan.status === 'active'}
        period={plan.refill_period}
        refillIn={plan.refill_in}
      />
    )
    const CREDITS = [
      'credits_social_media',
      'credits_tv_broadcast',
      'credits_website',
    ]

    const showCreditBlock = CREDITS.some(
      type =>
        plan[type].length && plan[type][0] !== 'none' && plan[type][0].length
    )

    return (
      <Fragment>
        <PlanCardGroup title={strings['page.plans.conditions.title']}>
          <Duration duration={plan.duration} unit={plan.duration_unit} />
          <Access live={plan.live_access} vod={plan.vod_access} />
          {plan.auto_renewal ? (
            <StartAt startAt={plan.start_at} />
          ) : (
            <ValidTill expiresAt={plan.expires_at} />
          )}
          <ArchivalRights archivalRights={plan.archiving_rights} />
        </PlanCardGroup>
        {(!company.any_limit || isMaster) && (
          <PlanCardGroup
            title={strings['page.plans.balance.title']}
            subtitle={balanceSubtitle}
          >
            {['regular_content', 'pool', 'spherical'].map(type => (
              <EnterpriseBalanceItem
                key={type}
                balance={plan.balance.actual_balance[type]}
                limit={plan.balance.limits[type]}
              />
            ))}
          </PlanCardGroup>
        )}

        {company.any_limit && (
          <PlanCardGroup
            title={strings['page.plans.limits.title']}
            subtitle={balanceSubtitle}
          >
            {['regular_content', 'pool', 'spherical'].map(type => (
              <EnterpriseBalanceItem
                key={type}
                balance={plan.user_limit.actual_balance[type]}
                limit={plan.user_limit.limits[type]}
                isLimitsBlock
              />
            ))}
          </PlanCardGroup>
        )}
        {showCreditBlock && (
          <PlanCardGroup
            title={strings['page.plan_details.rights_block.title']}
          >
            {CREDITS.map(type => {
              if (
                plan[type][0] &&
                plan[type][0] !== 'none' &&
                plan[type][0].length
              ) {
                return <RegionUsageRights key={type} type={type} plan={plan} />
              }
            })}
          </PlanCardGroup>
        )}
        {plan.auto_renewal && (
          <PlanCardGroup
            title={strings['page.plan_details.rights_block.auto_renewal']}
          >
            <PlanDetailsGroupParam
              label={plural(
                plan.renewal_duration,
                strings['page.plan_details.rights_block.renewal_duration']
              )}
            />
          </PlanCardGroup>
        )}
        {plan.mandatory_credits && (
          <PlanCardGroup
            params={[
              {
                label: strings['page.plans.mandatory.title'],
                value: (
                  <TextWithTooltip
                    text={strings['page.plans.mandatory.value']}
                    tooltip={strings[`page.plans.mandatory.tooltip`]}
                  />
                ),
              },
            ]}
          />
        )}
        {plan.branding_type === 'branded' && (
          <PlanCardGroup
            text={
              <TextWithTooltip
                text={strings['page.plans.branded.title']}
                tooltip={strings[`page.plans.branded.tooltip`]}
              />
            }
          />
        )}
      </Fragment>
    )
  }
}

export default DI(['user'])(EnterpriseDescription)
