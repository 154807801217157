import React from 'react'
// import PropTypes from 'prop-types';
import Component from 'components/component'

let id = 0

export default class Radio extends Component {
  static defaultProps = {
    className: 'radio-button',
    onClick() {},
    onChange() {},
    modificators: [],
    labelModificators: [],
    checked: false,
    value: '',
  }

  id = 'radio-' + id++

  render() {
    let {
      children,
      onClick,
      modificators,
      labelModificators,
      inputClassName,
      wrapperStyle,
      ...props
    } = this.props
    return (
      <div
        className={this.className}
        onClick={this.props.onClick}
        style={wrapperStyle}
      >
        <input
          {...props}
          id={this.id}
          type="radio"
          className={this.classList(
            `${this.props.className}__input`,
            this.props.inputClassName
          )}
        />
        <label htmlFor={this.id} className={this.labelClassName}>
          {children}
        </label>
      </div>
    )
  }

  get labelClassName() {
    return this.classList.apply(
      null,
      this.modificators(
        `${this.props.className}__label`,
        this.props.labelModificators
      )
    )
  }

  get className() {
    return this.classList.apply(
      null,
      [this.props.className].concat(
        this.modificators(this.props.className, this.props.modificators)
      )
    )
  }
}

export function Radios(props) {
  return <div {...props} className="radios" />
}
