import React from 'react'
import { DateTime } from 'luxon'
import { DI } from 'modules'
import Component from 'components/component'
import { plural } from 'helpers/strings'

const displayedStatuses = ['live', 'finished']

class StatusBase extends Component {
  state = {
    label:
      this.props.status === 'finished'
        ? this.props.cuttingAvailable
          ? 'recording'
          : this.props.status
        : 'live',
    hover: false,
    time: '',
    ico: null,
  }

  componentDidMount() {
    this.setLiveStatus()
  }

  componentWillUnmount() {
    clearTimeout(this.timeout)
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.setLiveStatus()
    }
  }

  setLiveStatus = () => {
    let start = +DateTime.fromISO(this.props.time).toUTC()
    let now = +DateTime.local().toUTC()
    const isRecordings =
      this.props.cuttingAvailable && this.props.status === 'finished'
    const nextDelay = 1000 * 60 * 15

    const disableStatuses = ['terminated', 'aborted']

    if (disableStatuses.includes(this.props.status)) {
      this.setState({
        label: 'live',
        time: '',
        ico: null,
      })

      return
    } else {
      this.setState({
        label: displayedStatuses.includes(this.props.status)
          ? isRecordings
            ? 'recording'
            : this.props.status
          : 'live',
      })
    }

    if (this.props.isOnLive) {
      let time = DateTime.fromMillis(now - start).toUTC()
      this.setState({
        time: Math.floor(+time / 1000 / 60 / 60) + time.toFormat(' : mm : ss'),
        ico: 'on',
        label: 'live',
      })

      this.timeout = setTimeout(this.setLiveStatus, 1000)
      return
    }

    if (start - now <= nextDelay || isRecordings) {
      let mins =
        start - now > 0
          ? DateTime.fromMillis(start - now)
              .toUTC()
              .toFormat('mm : ss')
          : '00 : 00'

      if (this.props.status === 'finished') {
        mins = DateTime.fromISO(this.props.time)
          .plus({ days: isRecordings ? 0 : 1 })
          .toUTC()
          .diffNow()
          .toFormat(isRecordings ? 'hh:mm:ss' : 'mm:ss')
      }
      const plurals = isRecordings
        ? this.props.common.strings[
            'page.dashboard.lives.recordings.time.format'
          ]
        : this.props.common.strings['page.dashboard.lives.live.time.format']

      this.setState({
        time: plural(mins, plurals),
        ico:
          this.props.status !== 'finished'
            ? 'off'
            : isRecordings
            ? 'rec'
            : null,
      })

      if (start - now > 0) {
        this.timeout = setTimeout(this.setLiveStatus, 1000)
      } else {
        clearTimeout(this.timeout)
      }

      return
    }

    this.setState({
      time: DateTime.fromMillis(start).toFormat(
        this.props.common.strings['page.dashboard.lives.live.date.format']
      ),
      ico: null,
    })
  }
}

class GridStatusComponent extends StatusBase {
  static defaultProps = {
    prefix: 'gc',
  }

  render() {
    const { prefix, status } = this.props

    return (
      <div className={`${prefix}-time`}>
        <span
          className={`${prefix}-time__ico ${prefix}-time__ico--${this.state.ico}`}
        >
          {this.state.label}
        </span>
        {this.props.status !== 'finished' && (
          <span className={`${prefix}__time`}>
            {this.props.status === 'finished' && 'expires '}
            {this.props.tbc && !this.props.isOnLive
              ? this.props.common.strings['time_to_be_confirmed']
              : this.state.time}
          </span>
        )}
        {this.props.status === 'finished' && this.props.cuttingAvailable && (
          <span className={`${prefix}__time`}>{this.state.time}</span>
        )}
      </div>
    )
  }
}

class ListStatusMobileComponent extends StatusBase {
  render() {
    return (
      <span className={`lc-mtime__ico lc-mtime__ico--${this.state.ico}`}>
        {this.state.label}
      </span>
    )
  }
}

class ListStatusComponent extends StatusBase {
  render() {
    return (
      <div className="lc-time">
        <span className={`lc-time__ico lc-time__ico--${this.state.ico}`}>
          {this.state.label}
        </span>
        {this.props.status !== 'finished' && (
          <span className="lc__time">
            {this.props.tbc && !this.props.isOnLive
              ? this.props.common.strings['time_to_be_confirmed']
              : this.state.time}
          </span>
        )}
        {this.props.status === 'finished' && this.props.cuttingAvailable && (
          <span className="lc__time">{this.state.time}</span>
        )}
      </div>
    )
  }
}

class ViewStatusComponent extends StatusBase {
  render() {
    return (
      <span className="live__status">
        <span
          className={`live__status-ico live__status-ico--${this.state.ico}`}
        >
          {this.state.label}
        </span>
        {this.props.status !== 'finished' && (
          <span className="live__time">
            {this.props.tbc && !this.props.isOnLive
              ? this.props.common.strings['time_to_be_confirmed']
              : this.state.time}
          </span>
        )}
        {this.props.status === 'finished' && this.props.cuttingAvailable && (
          <span className="live__time">{this.state.time}</span>
        )}
      </span>
    )
  }
}

export const GridStatus = DI(['user'])(GridStatusComponent)

export const ListStatusMobile = DI(['user'])(ListStatusMobileComponent)

export const ListStatus = DI(['user'])(ListStatusComponent)

export const ViewStatus = DI(['user'])(ViewStatusComponent)
