import React from 'react'
import { Link } from 'react-router-dom'
import Component from 'components/component'
import GCMark from 'components/gc-mark'
import { Link as TagLink } from 'components/link'
import * as routes from 'config/routes'
import { GridStatus } from 'pages/content/lives/live-status'
import { LazyLoadComponent } from 'react-lazy-load-image-component'
import Preview from './preview'
import { GridTime as VideoTime } from './video-time'
import Dropdown from 'components/dropdown'

export default class GCItem extends Component {
  static displayName = 'GCItem'

  state = {}

  setSlideshowMode = slideshowMode => ev => {
    this.setState({ slideshowMode })
  }

  onMouseMove = ev => {
    let { slides } = this.props,
      rect = ev.target.getBoundingClientRect()

    let slide = Math.round(
      ((ev.clientX - rect.left) / rect.width) * slides.length
    )

    this.setState({ slide })
  }

  renderTags = (tags, tagLink) => {
    if (this.props.isLive) {
      return tags.map(tag => (
        <TagLink
          key={tag.id}
          to={routes.landing.getPath(null, tag.slug)}
          className="gc__tag"
        >
          {tag.name}
        </TagLink>
      ))
    } else {
      return tags.map(
        tag =>
          tag.name && (
            <Link key={tag.id} to={tagLink(tag)} className="gc__tag">
              {tag.name}
            </Link>
          )
      )
    }
  }

  render() {
    const {
      slides,
      image,
      preview,
      isSpherical,
      isFree,
      isPool,
      isOnLive,
      isRemove,
      duration,
      title,
      isLive,
      time,
      status,
      tbc,
      tags,
      place,
      buttons,
      removeButton,
      hover,
      tagLink,
      modificator,
      addToListButton,
      lockedForUser,
      isArchive,
      isPremiumArchive,
      strings,
      cuttingAvailable,
    } = this.props
    const link = this.props.link
    const slide = slides[this.state.slide] || image
    const previewImage = this.state.slideshowMode ? slide : image
    const isButtonAddToList = !!addToListButton && addToListButton.length > 0

    return (
      <div
        className={this.classList(
          'gc _init',
          modificator && `gc--${modificator}`
        )}
      >
        <div
          className="gc-img"
          onMouseMove={slides.length ? this.onMouseMove : void 0}
          onMouseEnter={this.setSlideshowMode(true)}
          onMouseLeave={this.setSlideshowMode(false)}
        >
          <LazyLoadComponent
            placeholder={
              <div className="gc__img _loading">
                <div className="_img-loader"></div>
              </div>
            }
          >
            <Preview
              image={previewImage}
              link={link}
              hover={hover}
              preview={preview}
              title={title}
              sliderMode={this.state.slideshowMode}
              onClick={this.props.onClick}
            />
          </LazyLoadComponent>
          <GCMark
            isLocked={lockedForUser}
            titleList={[
              isFree && strings['vods.filters.type.free'],
              isPool && strings['vods.filters.type.pool'],
              // isArchive && strings['vods.filters.type.archive'],
              // isPremiumArchive && strings['vods.filters.type.premium_archive']
            ]}
            textList={[
              isFree && this.props.strings['project_card.bage.free.text'],
              isPool && this.props.strings['project_card.bage.pool.text'],
            ]}
            open={isFree || isPool ? hover : void 0}
          />
          {isSpherical && <span className="gc__360" />}

          {!!duration && <span className="gc__duration">{duration}</span>}
          {!!buttons.length && (
            <div className="gc-buttons" style={{ overflow: 'initial' }}>
              {!isLive && isButtonAddToList && (
                <div
                  className="live-download__select"
                  style={{ width: '49%', marginRight: '5px' }}
                >
                  {addToListButton.map(button => (
                    <Dropdown
                      key={button.text}
                      headerModificator={['big dropdown__head--add-to-list']}
                      bodyModificator={['to-list']}
                      items={button.items}
                      listsWithVideo={button.listsWithVideo}
                      placeholder={button.text}
                      getTitle={item => item.title}
                      getValue={item => item.slug}
                      onChange={button.onChange}
                      onOpen={button.onOpen}
                      valueIsPlaceholder
                    />
                  ))}
                </div>
              )}

              {buttons.map(button => (
                <button
                  style={
                    !isLive && isButtonAddToList
                      ? { width: '49%' }
                      : { width: '100%' }
                  }
                  key={button.text}
                  type="button"
                  className="gc__button"
                  onClick={button.callback}
                >
                  {button.text}
                </button>
              ))}
            </div>
          )}
        </div>
        <div className="gc-content">
          <Link to={link} className="gc__link" onClick={this.props.onClick} />
          <span className="gc__title">{title}</span>
          {isLive ? (
            <GridStatus
              cuttingAvailable={cuttingAvailable}
              time={time}
              status={status}
              tbc={tbc}
              isOnLive={isOnLive}
            />
          ) : (
            <VideoTime time={time} />
          )}
          <div className="gc-place">
            <Link to={link} className="gc__place" onClick={this.props.onClick}>
              {place}
            </Link>
          </div>

          {!!isRemove && (
            <div className="gc-remove">
              {removeButton.map(el => (
                <button
                  key={el.text}
                  type="button"
                  className="btn-bord"
                  disabled={this.state.disabledRemoveButton}
                  onClick={() => {
                    this.setState({ disabledRemoveButton: true })
                    el.callback()
                  }}
                >
                  {el.text}
                </button>
              ))}
            </div>
          )}
        </div>
        <div className="gc-tags">{this.renderTags(tags, tagLink)}</div>
      </div>
    )
  }
}
