import React from 'react'
import { DI } from 'modules'
import RestrictedContent from 'components/restricted-content'
import { UAE_REGULATED_CONTENT_LOCALE_KEY } from 'config/constants'
import Cookies from 'js-cookie'

// import DEPRECATED_moment from 'moment';
const availableLocales = ['en', 'ru', 'es', 'ar']
import { isServer } from 'store'

export default function page(Component, defaultProps = {}) {
  class Page extends React.Component {
    static defaultProps = {
      clear: false,
      landing: false,
      ...defaultProps,
    }

    constructor(props) {
      super(props)
      this.props.actions.common.clearPage(this.props.clear)
      this.props.actions.common.landingPage(this.props.landing)
      // moment.locale(this.props.match.params.locale)
    }

    componentDidMount() {
      window.copied_text = this.props.common.strings.copied_text
      window.isAdmin = this.props.user.currentUser.payload.user_role === 'admin'

      this.setLocale()

      if (this.props.pageName) {
        this.props.actions.common.addPageClass(`page--${this.props.pageName}`)
      }
    }

    componentDidUpdate(prevProps) {
      window.copied_text = this.props.common.strings.copied_text
      window.isAdmin = this.props.user.currentUser.payload.user_role === 'admin'
      if (this.props.match.params.locale !== prevProps.match.params.locale) {
        this.setLocale()
      }
    }

    componentWillUnmount() {
      if (this.props.pageName) {
        this.props.actions.common.removePageClass(
          `page--${this.props.pageName}`
        )
      }
    }

    setLocale() {
      if (availableLocales.includes(this.props.match.params.locale)) {
        this.props.actions.common.setLocale(this.props.match.params.locale)
      }
    }

    render() {
      const {
        actions,
        user,
        common,
        hasRestrictedContent,
        detailPage,
        ...props
      } = this.props

      const cookie_value = Cookies
        ? Cookies.get(UAE_REGULATED_CONTENT_LOCALE_KEY)
        : undefined
      const user_value = user ? user.restrictedContentAllowed : null

      const loading =
        isServer && user_value !== null
          ? false
          : common.isPendingContent ||
            (user.currentUser.pending && user_value === null) ||
            !user.currentUser.isInited

      let showRestrictedContentBlock = !(
        user_value !== null || cookie_value !== undefined
      )

      if (!showRestrictedContentBlock && detailPage) {
        showRestrictedContentBlock =
          common.isRestrictedContent &&
          (cookie_value == 'false' || user_value === false)
      }

      return (
        <>
          {hasRestrictedContent === true &&
            (showRestrictedContentBlock || loading) && (
              <RestrictedContent
                loading={loading}
                detailPage={detailPage}
                isRestrictedContent={common.isRestrictedContent}
              />
            )}
          <Component key={user.restrictedContentAllowed} {...props} />
        </>
      )
    }
  }

  return DI(['user'])(Page)
}
