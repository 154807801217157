import React from 'react'
import { Link } from '../link'

const HotBox = ({ title, text, buttonName, clsName }) => {
  return (
    <div className={`hot-box ${clsName}`} style={{ display: 'none' }}>
      <div className="hot-box__inner">
        <h3 className="hot-box__title">{title}</h3>
        <div className="hot-box__text">{text}</div>
        <Link to="/plans/request-subscription" className="button hot-box__button">
          {buttonName}
        </Link>
      </div>
    </div>
  )
}
export default HotBox
