import React, { useMemo } from 'react'
import { DateTime } from 'luxon'

import { DI } from 'modules'
import Component from 'components/component'
import Dropdown from 'components/dropdown'

const formatZone = code => DateTime.local().setZone(code).toFormat('ZZ')

export class TimezoneSelect extends Component {
  constructor(props) {
    super(props)
    this.browserTimeZone =
      Intl && Intl.DateTimeFormat
        ? Intl.DateTimeFormat().resolvedOptions().timeZone
        : 'Etc/UTC'
  }
  onSelectZone = (ev, selected) => {
    this.props.actions.user.selectTimezone(selected)
  }

  compareCities(a, b) {
    let cityA = a.code.split('/', 2)[1].toLocaleLowerCase()
    let cityB = b.code.split('/', 2)[1].toLocaleLowerCase()
    return (
      (b.offset < a.offset) - (a.offset < b.offset) ||
      (cityB < cityA) - (cityA < cityB)
    )
  }

  componentDidMount() {
    if (!Object.keys(this.props.user.timezone).length) {
      this.props.actions.user.selectTimezone(this.browserTimeZone)
    }
  }

  render() {
    const { locales, user, name, value } = this.props

    const sortedArray = locales.timezones.payload.sort(this.compareCities)

    const timezone = Object.keys(user.timezone).length
      ? user.timezone
      : this.browserTimeZone

    const params =
      name && value
        ? { name, value }
        : {
            onChange: this.onSelectZone,
            value: timezone,
          }

    return (
      <Dropdown
        items={sortedArray}
        getSelectedTitle={item => `GMT ${formatZone(item.code)}`}
        getTitle={item => {
          let city = (item.code || '').replace(/^\w+\//, '').replace('_', ' ')
          return `(GMT ${formatZone(item.code)}) ${city}`
        }}
        getValue={item => item.code}
        headerModificator="small"
        wrapperAttrs={{ dir: 'ltr' }}
        {...params}
      />
    )
  }
}

export default DI(['locales', 'user'])(TimezoneSelect)
