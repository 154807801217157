import React from 'react'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import { DI } from 'modules'
import page from 'hoc/page'
import { Helmet } from 'react-helmet'

import SearchForm from 'components/search-form'
import { LinkButton } from 'components/link'
import DashboardSliderContainer from 'pages/content/dashboard/dashboard-slider-container'
import DashboardSlider from 'pages/content/dashboard/dashboard-slider'

import 'submodules/responsivestyles/src/less/dashboard.less'
import { getLocaleFromUrl } from '../../../helpers/strings'

export class NotFoundPage extends React.Component {
  componentDidMount() {
    this.props.actions.videos.getRelatedList({
      ...this.props.common.searchParams,
    })
  }

  render() {
    const strings = this.props.common.strings
    const data = this.props.videos.related.payload
    const locale = getLocaleFromUrl(this.props.location.pathname)

    return (
      <section className="content not-found">
        <Helmet
          htmlAttributes={{
            status: 404,
          }}
        />
        <div className="iwrap">
          <div className="page-title">{strings['page.not_found.title']}</div>
          <div className="not-found-text">
            <p>{strings['page.not_found.text_1']} </p>
            <br />
            <p>
              {strings['page.not_found.text_3']}
              <a
                href={`mailto:${strings['clientdesk.email']}`}
                target="_blank"
                rel="noreferrer"
                rel="noopener noreferrer"
              >
                {strings['clientdesk.email']}
              </a>
            </p>
            <br />
            <p>
              {strings['page.not_found.text_2']}{' '}
              <Link to="/">{strings['page.not_found.link']}</Link>{' '}
              {strings['page.not_found.text_4']}
            </p>
          </div>
          <div
            style={{
              position: 'relative',
              marginBottom: '16px',
              marginTop: '24px',
            }}
          >
            <SearchForm visible />
            <div className="not-found-links buttons-group">
              <Link to={`/${locale}/videos`} className="button">
                All Videos
              </Link>
              <Link to={`/${locale}/events`} className="button">
                All Lives
              </Link>
            </div>
          </div>
          <DashboardSliderContainer>
            <DashboardSlider items={data.feed} />
          </DashboardSliderContainer>
        </div>
      </section>
    )
  }
}

function NotFoundText(props) {
  const strings = props.common.strings
  return (
    <div className="not-found-page">
      <div className="page-title">{strings['page.not_found.title']}</div>
      <div className="not-found-text">
        <p>
          {strings['page.not_found.text_1']} {strings['page.not_found.text_2']}
          <Link to="/">{strings['page.not_found.link']}</Link>
        </p>
        <br />
        <p>
          {strings['page.not_found.text_3']}
          <a
            href={`mailto:${strings['clientdesk.email']}`}
            target="_blank"
            rel="noreferrer"
            rel="noopener noreferrer"
          >
            {strings['clientdesk.email']}
          </a>
        </p>
      </div>
    </div>
  )
}

export const NotFoundBlock = DI()(NotFoundText)
export default withRouter(page(DI(['videos'])(NotFoundPage)))
