import { Elements } from '@stripe/react-stripe-js'
// import { loadStripe } from '@stripe/stripe-js/pure'
import React, { Fragment, useCallback, useEffect, useRef } from 'react'
import { DI } from 'modules'
import { Link } from 'components/link'
import page from 'hoc/page'
import { NotFoundBlock } from 'pages/content/not-found'
import Component from 'components/component'
import AcceptAgreementsModals from 'components/accept-agreements-modals'
import Outer from 'components/outer-click'
import ProfileHeading from './components/profile-heading'
import { Route, Switch } from 'react-router-dom'

import * as routes from 'config/routes'

import FilterMyContent from 'pages/business/user/my-content/filter'

import 'submodules/responsivestyles/src/less/account.less'
import 'submodules/responsivestyles/src/less/plan-details.less'
import 'submodules/responsivestyles/src/less/plan.less'

import {
  hasNotAgreementsOrMembers,
  isClient,
  noHasMembers,
} from 'modules/menu/config'
import * as config from 'config'

function PlanningSectionAnchors({ list }) {
  const ref = useRef()

  const anchorClickHandler = useCallback(event => {
    event.preventDefault()
    const id = event.target.getAttribute('href')
    if (id) {
      const floatHeaderEl = document.body.querySelector(
        '#root .float-header._fixed'
      )
      const yOffset = floatHeaderEl.offsetHeight + 20
      const _target = document.getElementById(id.replace('#', ''))
      const y =
        _target.getBoundingClientRect().top + window.pageYOffset - yOffset

      window.scrollTo({ top: y, behavior: 'smooth' })
    }
  }, [])

  useEffect(() => {
    list && ref.current.addEventListener('click', anchorClickHandler)
    return () => {
      list && ref.current.removeEventListener('click', anchorClickHandler)
    }
  }, [list])

  if (!list) return null

  return (
    <div className="sidemenu" style={{ marginTop: 50 }} ref={ref}>
      <ul className="sidemenu-list">
        <li className="sidemenu-item">
          {list.map((conf, index) => {
            const _to = conf.caption
            return (
              <a
                href={`#planning_anchor_${index}`}
                key={_to}
                className="sidemenu__link"
              >
                {_to}
              </a>
            )
          })}
        </li>
      </ul>
    </div>
  )
}

export class User extends Component {
  state = {
    open: false,
  }

  componentDidMount() {
    this.props.actions.user.getCompany().catch(() => {})
    this.props.actions.user.getCurrentUser().catch(() => {})
    this.props.actions.user.getAgreements().catch(() => {})
    this.props.actions.common.addPageClass('page--profile')
  }

  componentWillUnmount() {
    this.props.actions.common.removePageClass('page--profile')
  }

  toggleMenu = ev => {
    ev.preventDefault()
    this.setState({
      open: !this.state.open,
    })
  }

  closeMenu = ev => {
    if (this.state.open) {
      this.setState({
        open: false,
      })
    }
  }

  onAcceptAgreement() {
    this.props.actions.user.getAgreements().catch(() => {})
  }

  get stripe() {
    return null // loadStripe(config.STRIPE_API_KEY)
  }

  render() {
    const {
      menu: { userMenu: menu },
      user: { planningList, userAcceptingAgreements, agreements },
      common: { locale },
    } = this.props
    const isPlanningSection =
      this.props.location.pathname === routes.planning.getPath(locale)

    const [agreement] = userAcceptingAgreements
    const changedPlan = (agreements.payload || []).find(
      agr => agr.status === 'active' && !agr.user_accepted_at
    )

    const currentAgreement = changedPlan || agreement

    return (
      <Fragment>
        <ProfileHeading />
        <section className="profile-content">
          <div className="mobile-submenu">
            <Outer on={this.closeMenu}>
              <a
                href="#"
                className="mobile-submenu__open"
                onClick={this.toggleMenu}
              >
                Profile
              </a>
            </Outer>
            <div
              className={this.classList(
                'mobile-submenu-body',
                this.state.open && '_opened'
              )}
            >
              <ul className="mobile-submenu-list">
                {menu.map(item => (
                  <li key={item.link} className="mobile-submenu-item">
                    <Link
                      to={item.link}
                      className={this.classList(
                        'mobile-submenu__link',
                        item.active && '_active'
                      )}
                    >
                      {item.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          {(currentAgreement?.agreement_type === 'circle_branded' ||
            currentAgreement?.agreement_type === 'circle_onscreen_credits') && (
            <AcceptAgreementsModals
              onAccept={this.onAcceptAgreement}
              acceptOnly
            />
          )}
          <div
            className={this.classList(
              'profile j-account',
              this.props.common.wrapClass
            )}
          >
            <div className="profile-main">
              <Switch>
                <Route {...routes.myContent} />
                <Route {...routes.profile} />
                <Route {...routes.planDetails} />
                <Route {...routes.myList} />
                <Route {...routes.purchaseHistory} />
                {!this.props.user.planningAccess.error && (
                  <Route {...routes.planning} />
                )}

                {!(isClient(this.props) || noHasMembers(this.props)) && (
                  <Route {...routes.members} />
                )}

                {!(
                  isClient(this.props) ||
                  noHasMembers(this.props) ||
                  hasNotAgreementsOrMembers(this.props)
                ) && <Route {...routes.userLimits} />}

                <Elements stripe={this.stripe}>
                  <Route {...routes.billingSettings} />
                </Elements>

                <Route component={NotFoundBlock} />
              </Switch>
            </div>

            <div className="profile-side">
              <div className="sidemenu">
                <ul className="sidemenu-list">
                  {menu.map(item => (
                    <li key={item.link} className="sidemenu-item">
                      <Link
                        to={item.link}
                        className={this.classList(
                          'sidemenu__link',
                          item.active && '_active'
                        )}
                      >
                        {item.title}
                      </Link>
                    </li>
                  ))}
                </ul>

                {isPlanningSection && !!planningList.length && (
                  <PlanningSectionAnchors list={planningList} />
                )}
              </div>
              <Route
                path={routes.myContent.path}
                component={FilterMyContent}
                exact
              />
            </div>
          </div>
        </section>
      </Fragment>
    )
  }
}

export default page(DI(['menu', 'user'])(User))
