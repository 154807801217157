import React from 'react'
import Hammer from 'react-hammerjs'

import { DI } from 'modules'
import { isServer } from 'store'

import './style.css'

const TRANSITION_DURATION = 500
const BREAKPOINT_WIDTH = 700

import Component from 'components/component'

const SLIDER_OFFSET_VALUES = [
  '0',
  '50% + 15px',
  '33.33333% + 10px',
  '25% + 7.5px',
  '20% + 6px',
  '16.66666% + 5px',
  '14.28571% + 4.28571px',
  '12.5% + 3.75px',
  '11.11111% + 3.33333px',
  '10% + 3px',
  '9.09091% + 2.72727px',
  '8.33333% + 2.5px',
  '7.69231% + 2.30769px',
  '7.14286% + 2.14286px',
  '6.66666% + 2px',
  '6.25% + 1.875px',
  '5.88235% + 1.76471px',
  '5.55555% + 1.66666px',
  '5.26316% + 1.57895px',
  '5% + 1.5px',
]

class Slider extends Component {
  static defaultProps = {
    init: false,
    items: [],
    renderItem: slide => null,
    breakpoints: [],
    classNamePrefix: 'slider',
    hideDisabledButtons: true,
    infinity: false,
    autoScroll: 0,
    defaultInit: false,
  }

  state = {
    currentSlide: this.props.infinity ? this.props.items.length : 0,
    currentDot: 0,
    sliding: false,
    maxSlides: 1,
    init: this.props.defaultInit,
  }

  get fullWidth() {
    return isServer
      ? 0
      : Math.max(
          window.innerWidth,
          document.documentElement.clientWidth,
          document.body.clientWidth
        )
  }

  setAutoScroll = () => {
    this.autoscroll = setInterval(this.handleGoNext, this.props.autoScroll)
  }

  removeAutoScroll = () => {
    clearInterval(this.autoscroll)
  }

  componentDidMount() {
    this.defineSettings()
    if (this.props.autoScroll) {
      this.setAutoScroll()
    }

    window.addEventListener('resize', this.onResize)
  }

  componentDidUpdate(prevProps) {
    const itemsReason = prevProps.items.length !== this.props.items.length

    if (itemsReason) {
      this.defineSettings()
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize)
  }

  onResize = () => {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.defineSettings(true)
    }, 300)
  }

  defineSettings(saveCurrentSlide = false) {
    const { items, infinity, breakpoints } = this.props
    const state = {
      onPage: 1,
    }
    const count = items.length

    if (!saveCurrentSlide) {
      state.currentSlide = infinity ? items.length : 0
    }

    for (let i = 0; i < breakpoints.length; i++) {
      const point = breakpoints[i]
      if (this.fullWidth >= point.width) {
        state.onPage = point.count
        break
      }
    }

    state.maxSlides = Math.ceil(count / state.onPage)
    state.notEnough = state.maxSlides * state.onPage - count

    this.setState(state, () => {
      this.setState({
        init: true,
        offset: this.calcOffset(),
      })
    })
  }

  handleGoPrev = ev => {
    if (ev) {
      this.removeAutoScroll()
    }
    const { sliding } = this.state

    const onEnd = isPrevLast =>
      new Promise(resolve => {
        const func = (callback = () => {}) =>
          this.setState(
            prev => ({
              sliding: isPrevLast,
              currentDot:
                prev.currentDot === 0
                  ? this.props.items.length - 1
                  : prev.currentDot - 1,
              offset: this.calcOffset(prev.currentSlide),
            }),
            () => {
              callback()
              resolve()
            }
          )

        if (isPrevLast) {
          func(() => {
            setTimeout(() => {
              this.setState({ sliding: false })
            }, TRANSITION_DURATION)
          })
        } else {
          setTimeout(func, TRANSITION_DURATION)
        }
      })

    if (!sliding) {
      if (this.props.infinity) {
        let isPrevLast = false
        this.setState(
          prev => {
            const currentSlide = prev.currentSlide - 1
            return {
              currentSlide,
              sliding: true,
              offset: this.calcOffset(currentSlide),
            }
          },
          () => {
            if (this.state.currentSlide <= this.props.items.length) {
              this.setState(
                prev => ({
                  currentSlide: prev.currentSlide + this.props.items.length,
                }),
                () => onEnd(isPrevLast).then(() => ev && this.setAutoScroll())
              )
            } else {
              onEnd()
            }
          }
        )
      } else {
        this.setState(prev => {
          const currentSlide = Math.min(
            Math.max(0, prev.currentSlide - 1),
            prev.maxSlides - 1
          )
          return {
            currentSlide,
            sliding: true,
            offset: this.calcOffset(currentSlide),
          }
        }, onEnd)
      }
    }
  }

  handleGoNext = ev => {
    if (ev) {
      this.removeAutoScroll()
    }
    const { sliding } = this.state

    const onEnd = () =>
      new Promise(resolve => {
        setTimeout(() => {
          this.setState(
            prev => ({
              sliding: false,
              currentDot:
                prev.currentDot === this.props.items.length - 1
                  ? 0
                  : prev.currentDot + 1,
              offset: this.calcOffset(prev.currentSlide),
            }),
            () => resolve()
          )
        }, TRANSITION_DURATION)
      })

    if (!sliding) {
      if (this.props.infinity) {
        this.setState(
          prev => {
            const currentSlide = prev.currentSlide + 1
            return {
              currentSlide,
              sliding: true,
              offset: this.calcOffset(currentSlide),
            }
          },
          () => {
            if (this.state.currentSlide >= this.props.items.length * 2) {
              this.setState(
                prev => ({
                  currentSlide: prev.currentSlide - this.props.items.length,
                }),
                () => onEnd().then(() => ev && this.setAutoScroll())
              )
            } else {
              onEnd()
            }
          }
        )
      } else {
        this.setState(prev => {
          const currentSlide = Math.min(
            Math.max(0, prev.currentSlide + 1),
            prev.maxSlides - 1
          )
          return {
            currentSlide,
            sliding: true,
            offset: this.calcOffset(currentSlide),
          }
        }, onEnd)
      }
    }
  }

  handleDotClick = ev => {
    if (ev) {
      this.removeAutoScroll()
    }
    const index = ev ? Number(ev.target.dataset.id) : 0
    this.setState(
      {
        currentSlide: index + this.props.items.length,
        currentDot: index,
        sliding: true,
      },
      () => {
        this.setState(
          {
            offset: this.calcOffset(),
          },
          () => {
            setTimeout(() => {
              this.setState(
                prev => ({
                  sliding: false,
                }),
                () => {
                  if (ev) {
                    this.setAutoScroll()
                  }
                }
              )
            }, TRANSITION_DURATION)
          }
        )
      }
    )
  }

  calcOffset(currentSlide = this.state.currentSlide) {
    const { maxSlides, notEnough, onPage } = this.state

    const offsetBetweenItems = this.props.headBlock
      ? this.fullWidth >= BREAKPOINT_WIDTH
        ? currentSlide * 30
        : currentSlide * 0
      : this.fullWidth >= BREAKPOINT_WIDTH
      ? currentSlide * 30
      : currentSlide * 14

    if (currentSlide === 0) {
      return 0
    } else if (
      currentSlide === maxSlides - 1 &&
      this.fullWidth >= BREAKPOINT_WIDTH
    ) {
      return `calc(-${
        currentSlide * 100
      }% - ${offsetBetweenItems}px + ${notEnough} * (${
        SLIDER_OFFSET_VALUES[onPage - 1]
      })`
    }
    return `calc(-${currentSlide * 100}% - ${offsetBetweenItems}px)`
  }

  get scope() {
    const { infinity, items } = this.props
    return infinity ? [...items, ...items, ...items] : items
  }

  render() {
    const {
      infinity,
      items,
      renderItem,
      classNamePrefix,
      common: { locale },
    } = this.props
    const { currentSlide, currentDot, maxSlides, init, sliding, offset } =
      this.state
    const offsetDir = locale === 'ar' ? 'right' : 'left'

    if (!items?.length) {
      return null
    }

    return (
      <Hammer
        onSwipeLeft={locale === 'ar' ? this.handleGoPrev : this.handleGoNext}
        onSwipeRight={locale === 'ar' ? this.handleGoNext : this.handleGoPrev}
        direction="DIRECTION_HORIZONTAL"
      >
        <div
          className={this.classList(
            `${classNamePrefix}-outer _slider-outer`,
            init && '_init',
            sliding && '_sliding'
          )}
        >
          <div className={`${classNamePrefix}-prev`}>
            <div className={`${classNamePrefix}-prev-item`}>
              <div className={`${classNamePrefix}-prev-helper`}>
                {(infinity || currentSlide !== 0) && (
                  <button
                    type="button"
                    className={`${classNamePrefix}__prev`}
                    onClick={this.handleGoPrev}
                  />
                )}
              </div>
            </div>
          </div>
          <div className={`${classNamePrefix}-next`}>
            <div className={`${classNamePrefix}-next-item`}>
              <div className={`${classNamePrefix}-next-helper`}>
                {(infinity || currentSlide < maxSlides - 1) && (
                  <button
                    type="button"
                    className={`${classNamePrefix}__next`}
                    onClick={this.handleGoNext}
                  />
                )}
              </div>
            </div>
          </div>
          <div className={`${classNamePrefix}`}>
            <div
              className={`${classNamePrefix}-list`}
              style={{
                [offsetDir]: offset,
              }}
            >
              {this.scope.map((item, index) =>
                renderItem(
                  item,
                  index,
                  [
                    currentSlide,
                    currentSlide - items.length,
                    currentSlide + items.length,
                  ].includes(index)
                )
              )}
            </div>
            {classNamePrefix === 'dashboard-head' && (
              <div
                className="dashboard-head-dots"
                style={{
                  [offsetDir]: offset,
                }}
              >
                {items.map((item, index) => {
                  return (
                    <div
                      className={this.classList(
                        'dashboard-head-dot',
                        index === currentDot && '_active'
                      )}
                      data-id={index}
                      key={`${item.id}-${index}1`}
                      onClick={this.handleDotClick}
                    />
                  )
                })}
              </div>
            )}
          </div>
        </div>
      </Hammer>
    )
  }
}

export default DI()(Slider)
