// import DEPRECATED_moment from 'moment';
import Tooltip from 'components/tooltip'
import { plural } from 'helpers/strings'
import { DateTime } from 'luxon'
import { DI } from 'modules'
import React, { Fragment } from 'react'
import { PlanDetailsGroupParam } from '../plan-card-group'

export const BillingPeriod = DI()(({ period, common }) => {
  const { strings } = common
  return (
    <PlanDetailsGroupParam
      label={strings['page.plans.conditions.billing_period.title']}
      value={
        strings[`page.plans.conditions.billing_period.${period || 'year'}`]
      }
    />
  )
})

export const Duration = DI()(({ duration, unit, common }) => {
  const { strings } = common
  return (
    <PlanDetailsGroupParam
      label={strings['page.plans.conditions.duration.title']}
      value={duration + ' ' + strings[`page.plans.conditions.duration.${unit}`]}
    />
  )
})

export const Access = DI()(({ live, vod, common }) => {
  const { strings } = common
  const value =
    (live &&
      vod &&
      strings['page.plans.conditions.access.all_content.value']) ||
    (live && strings['page.plans.conditions.access.live.value']) ||
    (vod && strings['page.plans.conditions.access.vod.value']) ||
    strings['page.plans.conditions.access.pass.value']
  return (
    <PlanDetailsGroupParam
      label={strings['page.plans.conditions.access.title']}
      value={value}
    />
  )
})

export const StartAt = DI()(({ startAt, common }) => {
  const { strings } = common

  return (
    <PlanDetailsGroupParam
      label={strings['page.plans.conditions.start_at.title']}
      value={DateTime.fromISO(startAt)
        .toUTC()
        .toFormat(strings['page.plans.conditions.start_at.format'])}
    />
  )
})

export const ValidTill = DI()(({ expiresAt, common }) => {
  const { strings } = common

  return (
    <PlanDetailsGroupParam
      label={strings['page.plans.conditions.valid_till.title']}
      value={DateTime.fromSQL(expiresAt).toFormat(
        strings['page.plans.conditions.valid_till.format']
      )}
    />
  )
})

export const ArchivalRights = DI()(({ archivalRights, common }) => {
  const { strings } = common

  return (
    <PlanDetailsGroupParam
      label={strings['page.plans.conditions.archival_rights.title']}
      value={
        strings[
          `page.plans.conditions.archival_rights.${
            archivalRights || 'life_of_agreement'
          }`
        ]
      }
    />
  )
})

export const EnterpriseBalanceItem = DI()(
  ({ limit, balance, common, isLimitsBlock }) => {
    const { strings } = common
    if (!limit || !balance) {
      return null
    }
    const unlimited = isLimitsBlock
      ? limit.period_limit === null
      : limit.unlimited_access
    const label = (
      <TextWithTooltip
        text={strings[`page.plans.balance.${limit.limit_type}.title`]}
        tooltip={strings[`page.plans.balance.${limit.limit_type}.tooltip`]}
      />
    )
    const balanceText = (
      <Fragment>
        <p>
          {Math.max(0, balance.quota) +
            strings['page.plans.balance.items_left_of'] +
            limit.period_limit}
        </p>
        {limit.top_up_limit > 0 && (
          <p>
            {balance.top_up_quota +
              strings['page.plans.balance.top_up_left_of'] +
              limit.top_up_limit +
              strings['page.plans.balance.top_up_items_for'] +
              limit.top_up_item_price +
              strings['page.plans.balance.top_up_excl_vat']}
          </p>
        )}
      </Fragment>
    )

    const value = unlimited
      ? strings['page.plans.balance.unlimited']
      : balanceText

    return <PlanDetailsGroupParam label={label} value={value} />
  }
)

export const PassBalanceItem = DI()(
  ({ limit, balance, isLimitsBlock, common }) => {
    const { strings } = common
    if (!limit || !balance) {
      return null
    }
    const unlimited = isLimitsBlock
      ? limit.period_limit === null
      : limit.unlimited_access

    const label = (
      <TextWithTooltip
        text={strings[`page.plans.balance.${limit.limit_type}.title`]}
        tooltip={strings[`page.plans.balance.${limit.limit_type}.tooltip`]}
      />
    )
    const value = unlimited
      ? strings['page.plans.balance.unlimited']
      : Math.max(0, balance.quota) +
        strings['page.plans.balance.items_left_of'] +
        limit.period_limit

    return <PlanDetailsGroupParam label={label} value={value} />
  }
)

export const RegionUsageRights = DI()(({ common: { strings }, type, plan }) => {
  const label = `${strings[`page.plan_details.rights_block.${type}`]}:`

  const value = plan[type].reduce((prev, curr, index, arr) => {
    if (curr.length !== 0) {
      curr = strings[`page.plan_details.rights_block.${curr}`]

      return arr.length === index || index === 0 ? curr : `${prev}, ${curr}`
    } else {
      return prev
    }
  }, '')

  return <PlanDetailsGroupParam label={label} value={value} />
})

export const BalanceSubtitle = DI()(({ show, period, refillIn, common }) => {
  const { strings } = common

  if (!show) {
    return null
  }

  return (
    <Fragment>
      {strings['page.plan_details.agreements.enterprise.balance.refill_1']}
      {period}{' '}
      {plural(
        period,
        strings['page.plan_details.agreements.enterprise.balance.refill_2']
      )}
      <br />
      {refillIn && (
        <Fragment>
          {strings['page.plan_details.agreements.enterprise.balance.refill_3']}
          {refillIn}{' '}
          {plural(
            refillIn,
            strings['page.plan_details.agreements.enterprise.balance.refill_4']
          )}
        </Fragment>
      )}
    </Fragment>
  )
})

export function TextWithTooltip({ text, tooltip }) {
  return (
    <Fragment>
      {text}
      &nbsp;
      <Tooltip content={tooltip}>
        <span className="question" />
      </Tooltip>
    </Fragment>
  )
}
