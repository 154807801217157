import React, { Fragment } from 'react'
import { Link } from 'components/link'
import Component from 'components/component'
import FollowTag from 'pages/content/components/follow-tag'

export default class DashboardSliderContainer extends Component {
  static defaultProps = {
    title: '',
    text: '',
    button: null,
  }

  render() {
    return (
      <div className="dashboard-slider-container plausible__dashboard-slider">
        <div className="dashboard-slider__title">
          <div className="page-title page-title--dashboard j-page-title">
            <span className="page-title__left j-page-title-left">
              <span className="page-title__text page-title__text--with-follow">
                {this.props.title}
              </span>
              {this.props.tag && (
                <FollowTag
                  tag={this.props.tag}
                  className="btn-bord--follow"
                  name={this.props.title}
                />
              )}
            </span>
            <span className="page-title__right j-page-title-right _init">
              {this.props.button && (
                <Link
                  to={this.props.button.link}
                  className="btn-bord btn-bord--arrow dashboard-slider__side-button"
                >
                  <span className="btn-bord__text">
                    {this.props.button.text}
                  </span>
                </Link>
              )}
            </span>
          </div>
          <div className="page-text">{this.props.text}</div>
        </div>
        <Fragment>
          {this.props.children}
          {this.props.button && (
            <Link
              to={this.props.button.link}
              className="btn-bord dashboard-slider__bottom-button"
            >
              {this.props.button.text}
            </Link>
          )}
        </Fragment>
      </div>
    )
  }
}
