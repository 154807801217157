import React from 'react'
import { withRouter } from 'react-router'
import { DI } from 'modules'
import Component from 'components/component'
import Dropdown from 'components/dropdown'
import Outer from 'components/outer-click'
import { Link } from 'components/link'

export class PageSubmenu extends Component {
  static defaultProps = {
    items: [],
  }

  render() {
    const { items, location, withoutParams } = this.props
    const param = withoutParams ? '' : location.search || ''

    return (
      <div className="page-submenu">
        <ul className="page-submenu-list">
          {items.map(item => (
            <li key={item.link} className="page-submenu-item">
              <Link
                to={item.link + param}
                className={this.classList(
                  'page-submenu__link',
                  item.active && '_active'
                )}
              >
                {item.title}
              </Link>
            </li>
          ))}
        </ul>
        <MenuDropdown
          items={items}
          getValue={item => item.path}
          value={this.props.location.pathname}
          param={param}
        />
      </div>
    )
  }
}

class PMenuDropdown extends Dropdown {
  render() {
    const { open: isOpen } = this.state
    const { items, param, placeholder } = this.props
    const selected = items.find(item => item.active)

    const notSelected = !selected
    const title = notSelected ? placeholder : this.getTitle(selected)

    return (
      <div className="page-submenu-dropdown">
        <button
          type="button"
          className="page-submenu-dropdown__current"
          onClick={this.open}
        >
          {title}
        </button>
        <Outer on={this.close}>
          <div
            className={this.classList(
              'page-submenu-dropdown-body',
              isOpen && '_opened'
            )}
          >
            {items.map((item, index) => (
              <div
                key={item.link}
                className="page-submenu-dropdown-item"
                onClick={this.onSelect(item, index)}
              >
                <Link
                  to={item.link + param}
                  className={this.classList(
                    'page-submenu-dropdown__item',
                    item.active && '_active'
                  )}
                >
                  {item.title}
                </Link>
              </div>
            ))}
          </div>
        </Outer>
      </div>
    )
  }
}

export const MenuDropdown = withRouter(PMenuDropdown)

export default DI(['tags'])(withRouter(PageSubmenu))
