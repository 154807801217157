import React from 'react'
import Item from '../item'
import { LivesItemBase } from 'pages/content/lives/live-base'
import { DI } from 'modules'
import { livesView } from 'config/routes'
import { cyrillicTitle } from 'helpers/strings'

const imageSizes = {
  small: 'v273x154',
  medium: 'v575x323',
  large: 'v960x540',
}

export class LiveItemAdapter extends LivesItemBase {
  static defaultProps = {
    imageSize: 'medium',
  }

  toggleFollowing = ev => {
    const live = this.preloaded
    this.props.actions.lives.changeSubscription(
      this.live.slug,
      !live.subscribed
    )
  }

  render() {
    const preloaded = this.preloaded
    const live = this.props.live
    const imageSize = imageSizes[this.props.imageSize]

    const isSignedIn = this.props.user.isSignedIn
    const isOnLive = live.status === 'live' || this.state.ico === 'on'
    const isNext = live.status === 'next' || this.state.ico === 'off'

    const geoBlock =
      isSignedIn && live.blocked_countries_value_ids
        ? live.blocked_countries_value_ids.includes(
            this.props.user.companyCountry?.id?.toString()
          )
        : false

    const allowFollowing = Boolean(
      !geoBlock && isSignedIn && !isOnLive && !isNext
    )
    const followText =
      this.props.common.strings[
        preloaded.subscribed
          ? 'page.my_event.buttons.unfollow'
          : 'page.my_event.buttons.follow'
      ]

    const { preview_target_data = {} } = live

    const buttons = [
      {
        text: this.props.checkingFollow
          ? this.props.common.strings['page.my_event.buttons.loading']
          : followText,
        callback: this.props.checkingFollow ? () => {} : this.toggleFollowing,
        enabled: allowFollowing,
        disabled: this.props.checkingFollow,
      },
    ].filter(item => item.enabled)

    const title = cyrillicTitle(live.title)

    let link = livesView.getRawPath(
      this.props.common.locale,
      `${live.slug}/${encodeURIComponent(title)}`
    )

    const isRecordings = live.cutting_available && live.status === 'finished'
    const time = isRecordings
      ? live.recording_access_till
      : live.status === 'finished'
      ? live.end_at
      : live.start_at

    return (
      <Item
        isLive
        cuttingAvailable={live.cutting_available}
        aggregatedLimitType={live.aggregated_limit_type}
        link={link}
        status={live.status}
        tbc={!isRecordings && live.start_at_not_finalized}
        title={live.title}
        image={live.image[imageSize].url}
        time={time}
        // itemRegion={live.region_name}
        itemCountry={live.country_name}
        itemLocation={live.location}
        tags={live.tags}
        isPool={live.limit_type === 'pool'}
        isFree={live.free_access}
        isSpherical={live.format === 'spherical'}
        isOnLive={isOnLive}
        buttons={buttons}
        preview={
          isOnLive || isNext
            ? (preview_target_data || {}).hls_playback_url
            : void 0
        }
        view={this.props.view}
        tagLink={() => link}
        modificator={this.props.modificator}
        onClick={this.props.onClick}
      />
    )
  }
}

export default DI(['lives', 'user', 'common'])(LiveItemAdapter)
