import React, { useContext, useEffect } from 'react'
import { Link } from 'components/link'
import { videosList, videoView, livesView } from 'config/routes'
import useAnalytics from 'hooks/use-analytics'
import { DI } from 'modules'

const DashboardBannerLink = props => {
  const getLink = (banner, withOrigin) => {
    let origin = ''
    if (withOrigin) {
      origin = document.location.origin
    }
    switch (banner.target_link_to) {
      case 'video_project': {
        return origin + videoView.getPath('', banner.video_project_extid)
      }
      case 'live_event': {
        return origin + livesView.getPath('', banner.live_event_extid)
      }
      case 'tag': {
        return (
          origin + videosList.getPath() + `?q[tags_id_in][]=${banner.tag_id}`
        )
      }
      default: {
        return banner.custom_url
      }
    }
  }

  const { banner } = props
  const url = getLink(banner)

  const { trackClick, trackInView } = useAnalytics({
    groupEvent: 'ACTION_BANNER',
    clickType: 'click_banner',
    inViewType: 'view_banner',
    trackData: {
      id: banner.id,
      title: banner.title,
      url: getLink(banner, true),
      location: {
        page_layout: 'first_page',
        list: 'none',
      },
    },
  })

  const onClick = ev => {
    trackClick()
    if (window.ga) {
      window.ga('set', {
        dimension1: this.props.user.currentUser.payload.id,
        dimension2: banner.id,
        dimension3: banner.title,
        dimension4: getLink(banner, true),
        dimension5: new Date().getTime(),
      })
      window.ga('send', 'pageview')
    }
  }

  const { skipInView, ...linkProps } = props

  if (banner.custom_url) {
    return (
      <a
        ref={!skipInView ? trackInView : null}
        target="_blank"
        rel="noreferrer"
        href={url}
        {...props}
        onClick={onClick}
        data-action-type="banner"
      >
        {banner.title}
      </a>
    )
  } else {
    return (
      <Link
        ref={!skipInView ? trackInView : null}
        to={url}
        {...linkProps}
        onClick={onClick}
      >
        {banner.title}
      </Link>
    )
  }
}

export default DI(['user'])(React.memo(DashboardBannerLink))
