import React from 'react'
import Slider from 'components/slider'

import DashboardBanner from './dashboard-banner'

export default function DashboardHeadBlock(props) {
  return (
    <section className="dashboard-head-block">
      <Slider
        defaultInit
        infinity
        autoScroll={15 * 1000}
        items={props.items}
        classNamePrefix="dashboard-head"
        debug={props.debug}
        headBlock={true}
        renderItem={(item, index, isActive) => (
          <DashboardBanner
            key={item.id + '-' + index}
            isActive={isActive}
            banner={item}
          />
        )}
      />
    </section>
  )
}
