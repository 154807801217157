import React from 'react'
import Component from 'components/component'

import Pass from './pass'
import Enterprise from './enterprise'
import PassUnaprooved from './pass-unaprooved'
import Circle from './circle'

function getComponent(plan) {
  switch (plan.agreement_type) {
    case 'pass': {
      if (plan.status === 'unapproved') {
        return PassUnaprooved
      } else {
        return Pass
      }
    }
    case 'circle_branded':
    case 'circle_onscreen_credits': {
      return Circle
    }
    default: {
      return Enterprise
    }
  }
}

export default class AgreementDescription extends Component {
  render() {
    const Component = getComponent(this.props.data)

    return <Component {...this.props} />
  }
}
