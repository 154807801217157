import React from 'react'
import { DI } from 'modules'
import PageLoading from 'components/page-loading'
import * as config from 'config'
import * as routes from 'config/routes'
import { useHistory } from 'react-router'

import 'submodules/responsivestyles/src/less/restricted.less'

const style = {
  background: 'rgba(255, 255, 255, 0.7)',
  backdropFilter: 'blur(10px)',
  width: '100%',
  height: '100%',
  position: 'fixed',
  zIndex: 50,
}

const RestrictedContent = (props) => {
  const {
    user,
    actions,
    common: { strings, locale },
    detailPage,
    isRestrictedContent,
    loading
  } = props
  const history = useHistory()

  const onSetUAERegulatedContent = (value) => () => {
    actions.user.setRestrictedContentAllowed(value)
    if (!value) {
      if (detailPage !== undefined) {
        if (detailPage === 'videos') {
          history.push(routes.videosList.getPath(locale))
        } else {
          history.push(routes.livesList.getPath(locale))
        }
      }
    }
  }

  const isDirectRequest =
    (document && document.referrer === '') ||
    (document && document.referrer.indexOf(config.COMMON_DOMAIN) === -1)

  const stringKey =
    detailPage !== undefined && isDirectRequest && isRestrictedContent
      ? 'regulated_content_detail'
      : 'regulated_content'

  return (
    <div className="restricted-content" style={style}>
      <div className="restricted-content__body">
        {loading ? (
          <PageLoading />
        ) : (
          <>
            <span className="restricted-content__title">
              {strings[`uae.${stringKey}.title`]}
            </span>

            <p>{strings[`uae.${stringKey}.line_1`]}</p>
            <p>{strings[`uae.${stringKey}.line_2`]}</p>
            <div className="restricted-content__action">
              <button
                type="submit"
                className="button button--small-green"
                onClick={onSetUAERegulatedContent(false)}
              >
                {strings[`uae.${stringKey}.button.avoid`]}
              </button>
              <button
                type="submit"
                className="button button--small-green"
                onClick={onSetUAERegulatedContent(true)}
              >
                {strings[`uae.${stringKey}.button.proceed`]}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default DI(['user', 'common'])(RestrictedContent)
