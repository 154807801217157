import React from 'react'
import Component from 'components/component'
import { DI } from 'modules'

export class Pagination extends Component {
  static defaultProps = {
    page: 1,
    perPage: 10,
    count: 0,
    loadMore() {},
    switchPage() {},
    preloadPage() {},
  }

  state = {}

  componentDidMount() {
    const isMac = navigator.platform.toLowerCase().indexOf('mac') > -1

    document.addEventListener('keydown', this.onKeyDown)
    document.addEventListener('keyup', this.onKeyUp)

    this.setState({
      isMobile: window.Modernizr.touchevents,
      ctrlName: isMac ? 'Cmd' : 'Ctrl',
    })
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown)
    document.removeEventListener('keyup', this.onKeyUp)
  }

  get countPages() {
    const { perPage, count } = this.props
    return Math.ceil(count / perPage)
  }

  get buttons() {
    const { page } = this.props,
      countPages = this.countPages,
      THRESHOLD = this.state.isMobile ? 2 : 5

    const pages = [],
      addPage = (page, onlyDesktop) => {
        if (
          page === null ||
          (page > 0 && page <= countPages && pages.indexOf(page) === -1)
        ) {
          if (!onlyDesktop || !this.state.isMobile) {
            pages.push(page)
          }
        }
        return addPage
      }

    if (page < THRESHOLD) {
      for (let i = 1; i <= THRESHOLD; i++) {
        addPage(i)
      }
      addPage(countPages > THRESHOLD && null)(countPages)
    } else if (page + THRESHOLD > countPages) {
      addPage(1, true)(countPages - THRESHOLD > 1 && null, true)
      for (let i = countPages - THRESHOLD; i <= countPages; i++) {
        addPage(i)
      }
    } else {
      addPage(1, true)(page > 2 && null, true)(page - 1)(page)
      if (!this.state.isMobile) {
        addPage(page + 1)
      }

      addPage(page + 1 < countPages && null)(countPages)
    }

    return pages.map(page => {
      return (
        page && {
          page,
          onClick: ev => {
            ev.preventDefault()
            this.setPage(page, true)
            this.props.switchPage(page)
          },
        }
      )
    })
  }

  loadMore = ev => {
    ev.preventDefault()
    this.setPage(this.props.page + 1, false)
    this.props.loadMore('more')
  }

  prev = ev => {
    ev.preventDefault()
    const page = this.props.page - 1
    if (page > 0) {
      this.setPage(page, true)
      this.props.switchPage(page)
    }
  }

  next = ev => {
    ev.preventDefault()
    const page = this.props.page + 1
    if (page <= this.countPages) {
      this.setPage(page, true)
      this.props.switchPage(page)
    }
  }

  setPage(page, scrollToTop) {
    const { page: prevPage, pages } = this.props.common.pagination
    const {
      page: _page,
      pages: _pages,
      ...search
    } = this.props.common.searchParams
    const nextSearch = {
      ...search,
      page,
    }

    // debugger
    const prevPages = pages || [prevPage || 1]

    if (!scrollToTop) {
      nextSearch.pages = prevPages.concat(page)
    }

    this.props.actions.common.updateSearchParams(nextSearch, scrollToTop)
  }

  render() {
    if (this.buttons.length <= 1) {
      return null
    }

    return (
      <div className="pagination">
        {this.countPages > this.props.page && (
          <a
            href="#"
            className="btn-bord btn-bord--more"
            onClick={this.loadMore}
          >
            {this.props.common.strings['page.search.show_more']}
          </a>
        )}

        <div className="paging">
          {this.props.page > 1 && (
            <a href="#" className="paging__prev" onClick={this.prev}>
              <span className="paging__hint">{this.state.ctrlName}</span>
            </a>
          )}

          <div className="paging__helper">
            {this.buttons.map((button, index) => {
              if (!button) {
                return (
                  <span className="paging__hellip" key={'hellip' + index}>
                    &hellip;
                  </span>
                )
              } else if (this.props.page === button.page) {
                return (
                  <span key={button.page} className="paging__current">
                    {button.page}
                  </span>
                )
              } else {
                return (
                  <a
                    key={button.page}
                    href="#"
                    className="paging__link"
                    onClick={button.onClick}
                  >
                    {button.page}
                  </a>
                )
              }
            })}
            {/*<a href="#" className="paging__link">1</a>
                        <span className="paging__current">2</span>
                        <a href="#" className="paging__link paging__link--tablet">3</a>
                        <a href="#" className="paging__link paging__link--tablet">4</a>
                        <span className="paging__hellip">&hellip;</span>
                        <a href="#" className="paging__link">99</a>*/}
          </div>
          {this.props.page < this.countPages && (
            <a href="#" className="paging__next" onClick={this.next}>
              <span className="paging__hint">{this.state.ctrlName}</span>
            </a>
          )}
        </div>
      </div>
    )
  }

  onKeyDown = e => {
    if (e.key === 'Meta') {
      this.ctrl = true
    }
    if (this.ctrl || e.ctrlKey) {
      switch (e.code) {
        case 'ArrowLeft':
          return this.prev(e)

        case 'ArrowRight':
          return this.next(e)

        default:
          break
      }
    }
  }

  onKeyUp = e => {
    if (e.key === 'Meta') {
      this.ctrl = false
    }
  }
}

export default DI()(Pagination)
