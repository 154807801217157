import React from 'react'
import Component from 'components/component'
import { Link } from 'react-router-dom'
import Dropdown from 'components/dropdown'

import GCMark from 'components/gc-mark'
import Preview from './preview'

import { GridTime as VideoTime } from './video-time'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

let c = 0

export default class DBSItem extends Component {
  static displayName = 'DBSItem'
  state = {}

  c = 0

  constructor(props) {
    super(props)
    this.c = c++
  }

  setSlideshowMode = slideshowMode => ev => {
    this.setState({ slideshowMode })
  }

  onMouseMove = ev => {
    let { slides } = this.props,
      rect = ev.target.getBoundingClientRect()

    const slide = Math.round(
      ((ev.clientX - rect.left) / rect.width) * slides.length
    )

    this.setState({ slide })
  }

  render() {
    const {
      slides,
      image,
      link,
      preview,
      isSpherical,
      isFree,
      isPool,
      duration,
      title,
      time,
      buttons,
      addToListButton,
      hover,
      lockedForUser,
      isArchive,
      isPremiumArchive,
      strings,
    } = this.props

    const slide = slides[this.state.slide] || image
    const previewImage = this.state.slideshowMode ? slide : image

    const isButtonAddToList = !!addToListButton && addToListButton.length > 0

    return (
      <div className="dbs _init">
        <div
          className="dbs-img dbs-img--round"
          onMouseMove={slides.length ? this.onMouseMove : void 0}
          onMouseEnter={this.setSlideshowMode(true)}
          onMouseLeave={this.setSlideshowMode(false)}
        >
          <LazyLoadComponent
            placeholder={
              <div className="dbs__img _loading">
                <div className="_img-loader" />
              </div>
            }
          >
            <Preview
              image={previewImage}
              link={link}
              hover={hover}
              preview={preview}
              title={title}
              sliderMode={this.state.slideshowMode}
              className="dbs__img dbs__img--round"
            />
          </LazyLoadComponent>
          <GCMark
            isLocked={lockedForUser}
            titleList={[
              isFree && strings['vods.filters.type.free'],
              isPool && strings['vods.filters.type.pool'],
              // isArchive && strings['vods.filters.type.archive'],
              // isPremiumArchive && strings['vods.filters.type.premium_archive'],
            ]}
            textList={[
              isFree && this.props.strings['project_card.bage.free.text'],
              isPool && this.props.strings['project_card.bage.pool.text'],
            ]}
            open={isFree || isPool ? hover : void 0}
            classNamePrefix="dbs"
          />
          {isSpherical && <span className="gc__360" />}
          {!!buttons.length && (
            <div className="dbs-buttons">
              {isButtonAddToList && (
                <div
                  className="live-download__select"
                  style={{ width: '49%', marginRight: '5px' }}
                >
                  {addToListButton.map(button => (
                    <Dropdown
                      key={button.text}
                      headerModificator={['big dropdown__head--add-to-list']}
                      bodyModificator={['to-list']}
                      items={button.items}
                      listsWithVideo={button.listsWithVideo}
                      placeholder={button.text}
                      getTitle={item => item.title}
                      getValue={item => item.slug}
                      onChange={button.onChange}
                      onOpen={button.onOpen}
                      valueIsPlaceholder
                    />
                  ))}
                </div>
              )}

              {buttons.map(button => (
                <button
                  style={
                    isButtonAddToList ? { width: '49%' } : { width: '100%' }
                  }
                  key={button.text}
                  type="button"
                  className="dbs__button"
                  onClick={button.callback}
                >
                  {button.text}
                </button>
              ))}
            </div>
          )}

          {!!duration && <span className="dbs__duration">{duration}</span>}
        </div>
        <div className="dbs-content">
          <Link to={link} className="dbs__link">
            {title}
          </Link>
          <span className="dbs__title">{title}</span>
          <VideoTime time={time} view="dbs" />
        </div>
      </div>
    )
  }
}
