import React from 'react'
import { Link } from 'components/link'

import Component from 'components/component'

export default class DashboardLiveContainer extends Component {
  static defaultProps = {
    title: '',
    text: '',
    button: null,
  }

  render() {
    return (
      <div className="dashboard-live-container plausible__dashboard-slider">
        <div className="iwrap">
          <div className="dashboard-live-inner">
            <div className="dashboard-slider__title">
              <div className="page-title page-title--dashboard j-page-title">
                <span className="page-title__left j-page-title-left">
                  <span className="page-title__text">{this.props.title}</span>
                </span>
                <span className="page-title__right j-page-title-right _init">
                  {this.props.button && (
                    <Link
                      to={this.props.button.link}
                      className="btn-bord btn-bord--arrow btn-bord--arrow--gray dashboard-slider__side-button"
                    >
                      {this.props.button.text}
                    </Link>
                  )}
                </span>
              </div>
              <div className="page-text">{this.props.text}</div>
            </div>

            {this.props.children}
            {this.props.button && (
              <Link
                to={this.props.button.link}
                className="btn-bord dashboard-slider__bottom-button"
              >
                {this.props.button.text}
              </Link>
            )}
          </div>
        </div>
      </div>
    )
  }
}
