import React from 'react'
import { DI } from 'modules'
import { Modal } from 'components/modal'

import AgreementDescription from 'pages/business/user/plan-details/agreements'
import PlanCard from 'pages/business/user/plan-details/plan-card'

export function AgreementModal(props) {
  const close = ev => {
    props.actions.user.removeUserAcceptingAgreement(props.agreement.id)
  }

  return (
    <Modal onClose={props.acceptOnly ? null : close}>
      <div className="popup-agreement">
        {/* <button
                    type="button"
                    className="popup__close"
                    onClick={close}
                /> */}
        <PlanCard
          key={props.agreement.id}
          data={props.agreement}
          onAccept={props.onAccept}
          isModal
          acceptOnly={props.acceptOnly}
        >
          <AgreementDescription data={props.agreement} />
        </PlanCard>
      </div>
    </Modal>
  )
}

export default DI(['user'])(AgreementModal)
