import React, { Fragment } from 'react'
// import DEPRECATED_moment from 'moment';
import { DI } from 'modules'
import Component from 'components/component'
import { Modal } from 'components/modal'
import { DateTime } from 'luxon'

export class PassRenewalModal extends Component {
  static propTypes = {}

  static defaultProps = {}

  state = {
    open: false,
  }

  get expires_at() {
    return (
      this.props.plan.expires_at &&
      DateTime.fromISO(this.props.plan.expires_at).toFormat(
        this.props.common.strings[
          'page.plan_details.agreements.pass.opt_modal.date_format'
        ]
      )
    )
  }

  open = ev => {
    ev.preventDefault()
    this.setState({
      open: true,
    })
  }

  close = ev => {
    this.setState({
      open: false,
    })
  }

  setAutoRenewal = () => {
    const { plan } = this.props
    this.close()
    this.props.actions.user.setAutoRenewal(plan.id, !plan.auto_renewal)
  }

  renderText({ text, link }) {
    return (
      <Fragment>
        <p>{text}</p>
        <p>
          <a className="btn-bord btn-bord--thin" href="#" onClick={this.open}>
            {link}
          </a>
        </p>
      </Fragment>
    )
  }

  renderModal({ title, text_1, items, text_2, button, back }) {
    return (
      <Modal onClose={this.close}>
        <div className="popup-agreement">
          <span className="popup__title">{title}</span>
          <div className="popup__tip">{text_1}</div>
          <div className="popup__tip"></div>
          <div className="text">
            <ul>
              {items.map(item => {
                if (item.match('%expires_at')) {
                  let [before, after] = item.split('%expires_at')
                  return (
                    <li key={item}>
                      {before}
                      <strong>{this.expires_at}</strong>
                      {after}
                    </li>
                  )
                } else {
                  return <li key={item}>{item}</li>
                }
              })}
            </ul>
            <p>{text_2}</p>
          </div>
          <div className="popup-buttons">
            <div className="popup-button">
              <button
                type="button"
                className="text-button"
                onClick={this.setAutoRenewal}
              >
                {button}
              </button>
            </div>
            <div className="popup-button">
              <button type="button" className="button" onClick={this.close}>
                {back}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    )
  }

  render() {
    const { plan } = this.props
    const { strings } = this.props.common
    return (
      <Fragment>
        {this.renderText(
          plan.auto_renewal
            ? {
                text: strings[
                  'page.plan_details.agreements.pass.customer_text_true'
                ],
                link: strings[
                  'page.plan_details.agreements.pass.customer_link_true'
                ],
              }
            : {
                text: strings[
                  'page.plan_details.agreements.pass.customer_text_false'
                ],
                link: strings[
                  'page.plan_details.agreements.pass.customer_link_false'
                ],
              }
        )}
        {this.state.open &&
          this.renderModal(
            plan.auto_renewal
              ? {
                  title:
                    strings[
                      'page.plan_details.agreements.pass.opt_modal.on.title'
                    ],
                  text_1:
                    strings[
                      'page.plan_details.agreements.pass.opt_modal.on.text_1'
                    ],
                  items:
                    strings[
                      'page.plan_details.agreements.pass.opt_modal.on.items'
                    ],
                  text_2:
                    strings[
                      'page.plan_details.agreements.pass.opt_modal.on.text_2'
                    ],
                  button:
                    strings[
                      'page.plan_details.agreements.pass.opt_modal.on.button'
                    ],
                  back: strings[
                    'page.plan_details.agreements.pass.opt_modal.back'
                  ],
                }
              : {
                  title:
                    strings[
                      'page.plan_details.agreements.pass.opt_modal.off.title'
                    ],
                  text_1:
                    strings[
                      'page.plan_details.agreements.pass.opt_modal.off.text_1'
                    ],
                  items:
                    strings[
                      'page.plan_details.agreements.pass.opt_modal.off.items'
                    ],
                  text_2:
                    strings[
                      'page.plan_details.agreements.pass.opt_modal.off.text_2'
                    ],
                  button:
                    strings[
                      'page.plan_details.agreements.pass.opt_modal.off.button'
                    ],
                  back: strings[
                    'page.plan_details.agreements.pass.opt_modal.back'
                  ],
                }
          )}
      </Fragment>
    )
  }
}

export default DI(['user'])(PassRenewalModal)
