import React, { useEffect, useRef } from 'react'
import useAnalytics from 'hooks/use-analytics'

const Tracker = ({
  groupEvent,
  clickType,
  inViewType,
  trackData,
  children,
  isClick = true,
  isInView = true,
  isOpen,
}) => {
  const { trackClick, trackInView } = useAnalytics({
    groupEvent: groupEvent,
    clickType: clickType,
    inViewType: inViewType,
    trackData: trackData,
  })

  useEffect(() => {
    if (isOpen) {
      trackClick()
    }
  }, [isOpen])

  return (
    <div
      ref={isInView ? trackInView : null}
      onClick={isClick ? () => trackClick() : null}
      className="track"
    >
      {children}
    </div>
  )
}

export default Tracker
