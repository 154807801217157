import React from 'react'
import { DI } from 'modules'
import Component from 'components/component'
import AgreementModal from './agreement-modal'

export class AcceptAgreementsModals extends Component {
  static defaultProps = {
    onAccept() {},
    acceptOnly: false,
  }

  render() {
    const { userAcceptingAgreements, currentUser, agreements } = this.props.user
    const [agreement] = userAcceptingAgreements
    const isMaster = currentUser.payload.user_group == 'customer'

    const changedPlan = (agreements.payload || []).find(
      agr => agr.status === 'active' && !agr.user_accepted_at
    )

    const currentAgreement = changedPlan || agreement

    if (currentAgreement && isMaster) {
      return (
        <AgreementModal
          key={currentAgreement.id}
          agreement={currentAgreement}
          onAccept={this.props.onAccept}
          acceptOnly={this.props.acceptOnly}
        />
      )
    } else {
      return null
    }
  }
}

export default DI(['user'])(AcceptAgreementsModals)
