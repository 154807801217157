import React, { Fragment } from 'react'
import { withRouter } from 'react-router'
import Component from 'components/component'
import Tooltip from 'components/tooltip'
import { DI } from 'modules'
import ReactGA from 'react-ga'

export class FollowTag extends Component {
  static defaultProps = {
    tag: {},
    className: '',
    style: {},
  }

  afterAction = action => {
    this.props.actions.user.getCurrentUser().catch(() => {})
    this.props.actions.notifications.createNotification({
      type: 'success',
      children:
        this.props.common.strings[`page.dashboard.tags.${action}.success`],
    })
    ReactGA.event({
      category: this.props.location.pathname,
      action: action,
      label: this.props.tag.name,
    })
  }

  followClick = () => {
    if (this.props.user.isSignedIn) {
      this.props.actions.tags.followTag(this.props.tag.id).then(() => {
        this.afterAction('follow')
      })
    } else {
      this.props.actions.common.openSignInModal()
    }
  }

  unfollowClick = () => {
    this.props.actions.tags.unfollowTag(this.props.tag.id).then(() => {
      this.afterAction('unfollow')
    })
  }

  render() {
    const { user, common, tag } = this.props
    const strings = common.strings
    const currentUser = user.currentUser.payload
    const subscriptionExist =
      user.isSignedIn &&
      currentUser.tag_subscriptions.some(item => item.tag_id === tag.id)

    return (
      <Fragment>
        {subscriptionExist && (
          <Tooltip content={strings['page.dashboard.tags.tip.unfollow']}>
            <button
              type="button"
              className={this.classList(
                'btn-bord _active',
                this.props.className
              )}
              onClick={this.unfollowClick}
              style={this.props.style}
              data-action-name={tag.title}
            >
              <span className="_base">
                {strings['page.dashboard.tags.following']}
              </span>
              <span className="_over">
                {strings['page.dashboard.tags.unfollow']}
              </span>
            </button>
          </Tooltip>
        )}
        {subscriptionExist || (
          <Tooltip content={strings['page.dashboard.tags.tip.follow']}>
            <button
              type="button"
              className={this.classList('btn-bord', this.props.className)}
              onClick={this.followClick}
              style={this.props.style}
              data-action-name={this.props.name}
            >
              {strings['page.dashboard.tags.follow']}
            </button>
          </Tooltip>
        )}
      </Fragment>
    )
  }
}

export default DI(['common', 'user', 'notifications', 'tags'])(
  withRouter(FollowTag)
)
