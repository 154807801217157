import React from 'react'
import { DI } from 'modules'
import Component from 'components/component'
import Form from 'common/components/form/new-form'
import { Modal } from 'components/modal'

export class VerifyModal extends Component {
  static defaultProps = {
    delete() {},
  }

  onSubmit = (ev, form) => {
    this.props.delete(true)
  }
  onReset = () => {
    this.props.actions.common.closeVerifyModal()
  }

  render() {
    const { strings } = this.props.common

    return (
      <Modal>
        <Form>
          <div className="dialog">
            <div className="dialog-content">
              <span className="popup__title">
                {strings['page.my_lists_verify_title']}
              </span>
              <div className="dialog-text">
                {strings['page.my_lists_verify_text']}
              </div>
              <div className="dialog-buttons">
                <div className="popup-button">
                  <button
                    onClick={this.onReset}
                    type="reset"
                    className="button button--black"
                  >
                    {strings['page.payment.summary.cancel']}
                  </button>
                </div>
                <div className="popup-button">
                  <button
                    onClick={this.onSubmit}
                    className="button button--red"
                  >
                    {strings['page.members.show.confirm.button']}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </Modal>
    )
  }
}

export default DI(['user'])(VerifyModal)
