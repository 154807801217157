import React from 'react'
import PropTypes from 'prop-types'
import Component from 'components/component'

export function PlanDetailsGroupParam({ label, value }) {
  return (
    <div className="plan-details-param">
      <div className="plan-details-param__label">{label}</div>
      <div className="plan-details-param__value">{value}</div>
    </div>
  )
}

export default class PlanCardGroup extends Component {
  static propTypes = {
    title: PropTypes.string,
    params: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired,
      })
    ),
    text: PropTypes.object,
  }

  static defaultProps = {
    params: [],
  }

  render() {
    const { text, children, params } = this.props

    if (!text && !children && !params) {
      return null
    }

    return (
      <div className="plan-details-group">
        <div className="plan-details-group__name">
          <div className="plan-details-group__name-title">
            {this.props.title}
          </div>
          {this.props.subtitle && (
            <div className="plan-details-group__name-text">
              {this.props.subtitle}
            </div>
          )}
        </div>

        {(this.props.children || this.props.params.length > 0) && (
          <div className="plan-details-group__params">
            {this.props.children}
            {this.props.params.map((param, index) => (
              <PlanDetailsGroupParam key={index} {...param} />
            ))}
            {this.props.paramsText && (
              <div className="plan-details-group__params-text">
                {this.props.paramsText}
              </div>
            )}
          </div>
        )}
        {this.props.text && (
          <div className="plan-details-group__text">{this.props.text}</div>
        )}
      </div>
    )
  }
}
