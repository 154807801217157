import React from 'react'
// import PropTypes from 'prop-types';
import { DI } from 'modules'
import Component from 'components/component'

export class PlanCardFooter extends Component {
  static propTypes = {}

  static defaultProps = {}

  render() {
    const strings = this.props.common.strings
    const { text = strings['page.plan_details.agreements.contact_info.text'] } =
      this.props

    return (
      <div className="plan-details__footer plan-details-footer">
        <div className="plan-details-footer__text">{text}</div>
        <div className="plan-details-contacts">
          {strings['page.plan_details.agreements.contact_info.phone'] && (
            <div className="plan-details-contacts__item">
              <a
                className="_phone"
                href={`tel:${strings['page.plan_details.agreements.contact_info.phone']}`}
              >
                {strings['page.plan_details.agreements.contact_info.phone']}
              </a>
            </div>
          )}
          <div className="plan-details-contacts__item">
            <a
              className="_email"
              href={`mailto:${strings['clientdesk.email']}`}
            >
              {strings['page.plan_details.agreements.contact_info.email']}
            </a>
          </div>
        </div>
      </div>
    )
  }
}

export default DI([])(PlanCardFooter)
