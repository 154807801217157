import React from 'react'
import Component from 'components/component'
import { Link } from 'react-router-dom'

import Preview from './preview'
import GCMark from 'components/gc-mark'

import { GridStatus } from 'pages/content/lives/live-status'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export default class DBLItem extends Component {
  static displayName = 'DBLItem'

  render() {
    const {
      image,
      link,
      preview,
      isSpherical,
      isFree,
      isPool,
      title,
      time,
      status,
      tbc,
      buttons,
      hover,
      isOnLive,
      lockedForUser,
      isArchive,
      isPremiumArchive,
      strings,
      cuttingAvailable,
    } = this.props

    return (
      <div className="dbl j-gc _init">
        <div className="dbl-img dbl-img--round">
          <LazyLoadComponent
            placeholder={
              <div className="dbl__img _loading">
                <div className="_img-loader" />
              </div>
            }
          >
            <Preview
              image={image}
              link={link}
              hover={hover}
              preview={preview}
              title={title}
              className="dbl__img dbl__img--round"
            />
          </LazyLoadComponent>
          <GCMark
            isLocked={lockedForUser}
            titleList={[
              isFree && strings['vods.filters.type.free'],
              isPool && strings['vods.filters.type.pool'],
              // isArchive && strings['vods.filters.type.archive'],
              // isPremiumArchive && strings['vods.filters.type.premium_archive']
            ]}
            textList={[
              isFree && this.props.strings['project_card.bage.free.text'],
              isPool && this.props.strings['project_card.bage.pool.text'],
            ]}
            open={isFree || isPool ? hover : void 0}
            classNamePrefix="dbl"
          />
          {isSpherical && <span className="gc__360" />}
          {!!buttons.length && (
            <div className="dbl-buttons">
              {buttons.map(button => (
                <button
                  key={button.text}
                  type="button"
                  className="dbl__button"
                  onClick={button.callback}
                >
                  {button.text}
                </button>
              ))}
            </div>
          )}
        </div>
        <div className="dbl-content">
          <Link to={link} className="dbl__link">
            {title}
          </Link>
          <span className="dbl__title">{title}</span>
          <GridStatus
            cuttingAvailable={cuttingAvailable}
            time={time}
            status={status}
            tbc={tbc}
            isOnLive={isOnLive}
            prefix="dbl"
          />
        </div>
      </div>
    )
  }
}
