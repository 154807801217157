import React, { Fragment } from 'react'
import { DI } from 'modules'
import Component from 'components/component'
import Dropdown from 'components/dropdown'

export class AEFilterRelevant extends Component {
  onChangeOrder = value => ev => {
    ev.preventDefault()
    const { order, page, ...params } = this.props.common.searchParams

    if (value) {
      params.order = value
    }

    this.props.actions.common.updateSearchParams(
      params,
      this.props.common.isMobile
    )
  }

  render() {
    const { strings } = this.props.common
    const { search, order = '' } = this.props.common.searchParams

    if (!search) {
      return null
    }

    const orders = [
      {
        title: strings['page.search.order.latest'],
        value: '',
      },
      {
        title: strings['page.search.order.relevant'],
        value: 'relevant',
      },
    ]

    return (
      <Fragment>
        <div className="ae-filter-relevant__mobile">
          <Dropdown
            headerModificator={['small']}
            items={orders}
            value={order}
            onChange={(ev, value) => this.onChangeOrder(value)(ev)}
          />
        </div>
        <div className="ae-filter-relevant__desktop">
          <div className="ae-sort">
            {orders.map(item => (
              <a
                key={item.value}
                href="#"
                className={this.classList(
                  'ae-sort__item',
                  order === item.value && '_active'
                )}
                onClick={this.onChangeOrder(item.value)}
              >
                {item.title}
              </a>
            ))}
          </div>
        </div>
      </Fragment>
    )
  }
}

export default DI()(AEFilterRelevant)
