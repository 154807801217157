import React from 'react'
import { DI } from 'modules'
import Component from 'components/component'
import { Link } from 'components/link'
import * as routes from 'config/routes'

export class PassUnAprooved extends Component {
  static propTypes = {}

  static defaultProps = {}

  getTranslate = (key = '') => {
    const {
      common: { strings },
    } = this.props
    return strings[`page.plan_details.requests.pass.unapproved.${key}`]
  }

  render() {
    return (
      <div className="plan-details-text">
        <p>
          {this.getTranslate('text_1')}
          <Link to={routes.tos.getPath()}>{this.getTranslate('link')}</Link>
          {this.getTranslate('text2')}
        </p>
        <ul>
          <li>{this.getTranslate('text_3')}</li>
        </ul>
        <p className="_padded">{this.getTranslate('text_4')}</p>
        <ul>
          <li>
            {this.getTranslate('text_5')}
            <span className="question" data-tippy-content="[tooltip text]" />
          </li>
        </ul>
        <p>{this.getTranslate('text_6')}</p>
      </div>
    )
  }
}

export default DI([])(PassUnAprooved)
