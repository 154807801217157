import React, { Fragment } from 'react'
//import PropTypes from 'prop-types';
import { DI } from 'modules'
import Component from 'components/component'
import { Link } from 'components/link'
import Checkbox from 'components/form/checkbox'

import * as routes from 'config/routes'

export class AcceptAgreement extends Component {
  static propTypes = {}

  static defaultProps = {
    onAccept() {},
  }

  state = {
    checked: false,
  }

  onChange = ev => {
    this.setState({
      checked: ev.target.checked,
    })
  }

  accept = () => {
    this.props.actions.user.acceptAgreement(this.props.id).then(resp => {
      this.props.onAccept()
      return resp
    })
  }

  render() {
    const { strings } = this.props.common

    return (
      <Fragment>
        {/* {!this.props.acceptOnly && ( */}
        <div className="field">
          <Checkbox onChange={this.onChange} checked={this.state.checked}>
            {strings['page.plan_details.unaccepted.i_have_read']}

            {this.props.type === 'circle_branded' ||
            this.props.type === 'circle_onscreen_credits' ? (
              strings['page.plan_details.unaccepted.circle_plan']
            ) : (
              <Link to={routes.tos.getPath()}>
                {strings['page.plan_details.unaccepted.terms']}
              </Link>
            )}
          </Checkbox>
        </div>
        {/* )} */}
        <div className="field">
          <button
            className="button button--free"
            onClick={this.accept}
            disabled={!this.state.checked}
          >
            {this.props.type === 'circle_branded' ||
            this.props.type === 'circle_onscreen_credits'
              ? strings['page.plan_details.unaccepted.circle']
              : strings['page.plan_details.unaccepted.accept']}
          </button>
        </div>
      </Fragment>
    )
  }
}

export default DI(['user'])(AcceptAgreement)
