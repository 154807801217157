import React from 'react'
import Component from 'components/component'
import { DI } from 'modules'

import VideoItemAdapter from './adapters/video-item-adapter'
import LiveItemAdapter from './adapters/live-item-adapter'

class List extends Component {
  static defaultProps = {
    withFilter: false,
    listViewType: '',
  }

  render() {
    const viewType = this.props.listViewType || this.props.common.listViewType
    const grid = viewType === 'grid'
    const list = viewType === 'list'

    return (
      <div
        className={this.classList(
          grid && 'ae-list',
          grid && this.props.withFilter && 'ae-list--with-filter',
          list && 'lae-list',
          list && this.props.withFilter && 'lae-list--with-filter'
        )}
      >
        {this.props.children}
      </div>
    )
  }
}

export { VideoItemAdapter, LiveItemAdapter }
export default DI()(List)
