import { useState, useEffect, useCallback } from 'react'
import * as amplitude from '@amplitude/analytics-browser'
import { useInView } from 'react-intersection-observer'
import { useSelector } from 'react-redux'

const useAnalytics = props => {
  const { groupEvent, clickType, inViewType, trackData } = props

  const { common, user } = useSelector(state => ({
    common: state.common,
    user: state.user.currentUser.payload,
  }))

  const defaultTrackData = {
    ...trackData,
    location: {
      platform: 'int',
      locale: common.locale,
      ...trackData.location,
    },
    user_id: user?.id,
    company_id: user?.company_id,
  }

  const { ref } = useInView({
    triggerOnce: true,
    threshold: 0.8,
    onChange: (...v) => observerInstanceCallback(v),
  })

  const trackClick = (data, type) => {
    const { location, ...otherData } = data || {}
    const event_properties = {
      ...defaultTrackData,
      location: {
        ...defaultTrackData.location,
        ...(location || {}),
      },
      ...otherData,
    }

    amplitude.track({
      event_type: type || clickType,
      event_properties: event_properties,
      groups: { group: groupEvent },
    })
  }

  const trackInView = useCallback(
    el => {
      if (el) {
        ref(el)
      }
    },
    [groupEvent, trackData]
  )

  const observerInstanceCallback = ([inView, o]) => {
    if (inView) {
      amplitude.track({
        event_type: inViewType,
        event_properties: defaultTrackData,
        groups: { group: groupEvent },
      })
    }
  }

  return {
    trackClick,
    trackInView,
  }
}

export default useAnalytics
