import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Component from 'components/component'
import { Link } from 'react-router-dom'
import Image from 'components/image'
import VideoPlayer from 'components/video-player'

let stopActivePlayer = null

export default class Preview extends Component {
  static propTypes = {
    image: PropTypes.string,
    link: PropTypes.string.isRequired,
    preview: PropTypes.string,
    hover: PropTypes.bool,
    title: PropTypes.string,
  }

  static defaultProps = {
    className: 'gc__img',
  }

  state = {
    openPlayer: false,
  }

  componentWillUnmount() {
    if (this.state.openPlayer) {
      this.setState({
        openPlayer: false,
      })
      stopActivePlayer = null
    }
  }

  togglePlayer = () => {
    if (this.state.openPlayer) {
      this.setState({
        openPlayer: false,
      })
      stopActivePlayer = null
    } else {
      if (stopActivePlayer) {
        stopActivePlayer()
      }
      this.setState({
        openPlayer: true,
      })
      stopActivePlayer = this.togglePlayer
    }
  }

  render() {
    return (
      <Fragment>
        {this.props.hover && this.props.preview && (
          <span
            className={this.classList(
              'ae-item__play ae-item__play--card',
              this.state.openPlayer && '_stop'
            )}
            onClick={this.togglePlayer}
          />
        )}
        <Image
          loadingIndicator={!this.props.sliderMode}
          url={this.props.image}
          render={({ url, loading }) =>
            this.state.openPlayer ? (
              <Link to={this.props.link} onClick={this.props.onClick}>
                <VideoPlayer
                  className={this.props.className}
                  autoplay
                  watermark={false}
                  sources={[
                    {
                      src: this.props.preview,
                      type: 'application/x-mpegURL',
                    },
                  ]}
                />
              </Link>
            ) : (
              <Link
                to={this.props.link}
                onClick={this.props.onClick}
                className={this.classList(
                  this.props.className,
                  loading && ' _loading'
                )}
              >
                {loading ? (
                  <div className="_img-loader"></div>
                ) : (
                  <img src={url} alt={this.props.title} />
                )}
              </Link>
            )
          }
        />
      </Fragment>
    )
  }
}
