import React from 'react'
import { DI } from 'modules'
import Component from 'components/component'

export class ProfileHeading extends Component {
  render() {
    const user = this.props.user.currentUser.payload
    return (
      <section className="profile-heading">
        <div className="iwrap">
          <div className="profile-heading-name">
            <span className="profile-heading-name__text">
              {user.first_name}&nbsp;{user.last_name}
            </span>
            <span className="profile-heading-name__slash"></span>
          </div>
          <span className="profile-heading__corp">{user.unit_name}</span>
        </div>
      </section>
    )
  }
}

export default DI(['user'])(ProfileHeading)
