import Component from 'components/component'
import { DI } from 'modules'
import React, { Fragment } from 'react'
import PlanCardGroup from '../plan-card-group'
import {
  Access,
  ArchivalRights,
  BillingPeriod,
  PassBalanceItem,
  StartAt,
  ValidTill,
} from './params'
import PassRenewalModal from './pass-renewal-modal'

export class PassDescription extends Component {
  static propTypes = {}

  static defaultProps = {}

  render() {
    const strings = this.props.common.strings
    const { currentUser, company } = this.props.user
    const plan = this.props.data
    const isMaster = currentUser.payload.user_group === 'customer'
    const renewalModal =
      plan.status === 'active' && isMaster ? (
        <PassRenewalModal plan={plan} />
      ) : null

    return (
      <Fragment>
        <PlanCardGroup
          title={strings['page.plans.conditions.title']}
          paramsText={renewalModal}
        >
          <BillingPeriod period={plan.billing_period} />
          <Access />
          {plan.auto_renewal ? (
            <StartAt startAt={plan.start_at} />
          ) : (
            plan.status !== 'created' && (
              <ValidTill expiresAt={plan.expires_at} />
            )
          )}
          <ArchivalRights archivalRights={plan.archiving_rights} />
        </PlanCardGroup>
        {plan.status !== 'created' && (
          <Fragment>
            {(isMaster || !company.payload.any_limit) && (
              <PlanCardGroup title={strings['page.plans.balance.title']}>
                {['regular_content', 'pool', 'spherical'].map(type => (
                  <PassBalanceItem
                    key={type}
                    balance={plan.balance.actual_balance[type]}
                    limit={plan.balance.limits[type]}
                  />
                ))}
              </PlanCardGroup>
            )}
            {company.payload.any_limit && (
              <PlanCardGroup title={strings['page.plans.limits.title']}>
                {['regular_content', 'pool', 'spherical'].map(type => (
                  <PassBalanceItem
                    key={type}
                    balance={plan.user_limit.actual_balance[type]}
                    limit={plan.user_limit.limits[type]}
                    isLimitsBlock
                  />
                ))}
              </PlanCardGroup>
            )}
          </Fragment>
        )}
        <PlanCardGroup
          title={strings['page.plans.restricted.title']}
          text={
            <RestrictedUsageText
              text={strings['page.plans.restricted.text']}
              urls={[plan.url_1, plan.url_2].filter(Boolean)}
            />
          }
        />
      </Fragment>
    )
  }
}

function RestrictedUsageText(props) {
  return (
    <Fragment>
      <p>{props.text}</p>
      {props.urls.map(url => (
        <p key={url}>
          <a href={url} target="_blank" rel="noreferrer">
            {url}
          </a>
        </p>
      ))}
    </Fragment>
  )
}

export default DI(['user'])(PassDescription)
