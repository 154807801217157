import React from 'react'
import { DI } from 'modules'
import Component from 'components/component'
import TimezoneSelect from 'components/timezone-select'
import { plural } from 'helpers/strings'

import AEFilterRelevant from './ae-filter-relevant'
import AEFilterPlain from './ae-filter-plain'

export class AEFilter extends Component {
  onSelectZone = (ev, selected) => {
    this.props.actions.user.setTimezone(selected)
  }

  selectViewType = type => ev => {
    ev.preventDefault()
    this.props.actions.common.setListViewType(type)
  }

  render() {
    return (
      <div className="ae-filter">
        {this.props.hasOwnProperty('livesCount') && (
          <div className="ae-filter-count">
            <span className="ae-filter-count__count">
              {plural(
                this.props.livesCount,
                this.props.common.strings['page.lives.list.count_of_lives']
              )}
            </span>
          </div>
        )}
        <div className="ae-filter-view">
          <div className="ae-views">
            {this.props.common.listViewTypes.map(type => (
              <div key={type} className="ae-view">
                <a
                  href="#"
                  className={this.classList(
                    `ae__view ae__view--${type}`,
                    type === this.props.common.listViewType && '_active'
                  )}
                  onClick={this.selectViewType(type)}
                />
              </div>
            ))}
          </div>
        </div>
        {this.props.showRelevant && (
          <div className="ae-filter-relevant">
            <AEFilterRelevant />
          </div>
        )}
        <div className="ae-filter-time">
          <TimezoneSelect />
        </div>
      </div>
    )
  }
}
export { AEFilterPlain }
export default DI(['common'])(AEFilter)
