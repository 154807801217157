import React, { Fragment } from 'react'
import { DI } from 'modules'
import Component from 'components/component'
import { videosList, profile } from 'config/routes'

import { Link } from 'components/link'
import { Button } from 'components/form/button'

import List, { VideoItemAdapter } from 'components/list'

import { AEFilterPlain } from 'components/ae-filter'
import { withRouter } from 'react-router'
import PageLoading from 'components/page-loading'
import NotFoundPage from 'pages/content/not-found'
import page from 'hoc/page'

import Pagination from 'components/pagination'

import 'submodules/responsivestyles/src/less/account.less'
import 'submodules/responsivestyles/src/less/mylist.less'
import 'submodules/responsivestyles/src/less/all-events.less'
import 'submodules/responsivestyles/src/less/shared-lists.less'
import Cookies from 'js-cookie'

import { UAE_REGULATED_CONTENT_LOCALE_KEY } from 'config/constants'

const PER_PAGE = 10

export class ListView extends Component {
  static preload(props) {
    const list_id = props.match.params.id

    if (list_id) {
      return Promise.all([
        props.actions.user
          .getListItems(
            {
              list_id,
              page: 1,
              per_page: PER_PAGE,
              with_restricted_content: true,
              ...props.common.searchParams,
            },
            false
          )
          .then(() => {
            props.actions.common.wrapClass('profile--mylists')
          }),
      ])
    }
    return null
  }

  state = {
    isError: false,
    filterRestrictedContent: true,
  }

  componentDidMount() {
    this.currentList()
    ListView.preload(this.props)
    this.props.actions.common.wrapClass('profile--clear profile--mylists')

    const { restrictedContentAllowed } = this.props.user

    const isWithRestrictedContent =
      restrictedContentAllowed ||
      (Cookies && Cookies.get(UAE_REGULATED_CONTENT_LOCALE_KEY) === 'true')

    this.setState({ filterRestrictedContent: !isWithRestrictedContent })
  }

  componentWillUnmount() {
    this.props.actions.common.wrapClass(null)
  }

  currentList = () => {
    this.props.actions.user
      .getActiveList(this.props.match.params.id)
      .catch(error => {
        if (error) {
          this.setState({ isError: true })
        }

        throw error
      })
  }

  componentDidUpdate(prevProps, _prevState) {
    if (
      prevProps.user.myLists.payload.lists !==
      this.props.user.myLists.payload.lists
    ) {
      this.currentList()
    }

    const localeIsUpdated = this.props.common.locale !== prevProps.common.locale

    const paramsIsUpdated =
      this.props.common.searchParams !== prevProps.common.searchParams

    if (paramsIsUpdated || localeIsUpdated) {
      ListView.preload(this.props)
    }
  }

  handleClickToShowOriginalList = () => {
    this.setState({ filterRestrictedContent: false })
  }

  render() {
    const { strings } = this.props.common

    const { payload: data, isInited, pending } = this.props.user.activeList
    let listItems = (this.props.user.myListItems.payload || {}).lists || []

    if (this.state.filterRestrictedContent) {
      listItems = listItems.filter(item => !item.content.restricted_content)
    }

    const totalCount = (this.props.user.myListItems.payload || {}).count || 0

    const notificationIsShow =
      totalCount !== (this.props.user.myListItems.payload || {}).count

    const search = this.props.common.searchParams

    if (!isInited || pending) {
      return <PageLoading />
    }
    if (this.state.isError) {
      return <NotFoundPage />
    }

    return (
      <Fragment>
        <section className="profile-content shared-lists">
          <div
            className={this.classList(
              'profile j-account',
              this.props.common.wrapClass
            )}
          >
            <div className="profile-main">
              <div className="profile-zone-intro">
                <div className="wrap wrap--mobile">
                  <div className="profile-side-button profile-side-button--many profile-side-button--mylist">
                    <AEFilterPlain />
                  </div>
                </div>
              </div>

              <div className="profile-zone-list">
                <div className="wrap wrap--mobile">
                  <span className="profile-zone__title">{data.title}</span>
                </div>
              </div>

              {Boolean(
                notificationIsShow && this.state.filterRestrictedContent
              ) && (
                <div
                  className={`shared-list-notification ${
                    totalCount > 0
                      ? 'shared-list-notification--with-border'
                      : ''
                  }`}
                >
                  <div className="shared-list-notification__icon">
                    <div className="sln-icon">icon</div>
                  </div>
                  <div className="shared-list-notification__content">
                    <div className="sln-content">
                      <div className="sln-content__title">
                        {strings['page.lists.notification.title']}
                      </div>
                      <div className="sln-content__text">
                        {this.props.user.isSignedIn
                          ? strings[
                              'page.lists.notification.text_for_auth_user'
                            ]
                          : strings['page.lists.notification.text']}
                      </div>
                      <div className="sln-content__buttons">
                        {this.props.user.isSignedIn ? (
                          <Link to={profile.getPath()}>
                            <Button smallGreen>
                              {
                                strings[
                                  'page.lists.notification.btn.go_to_profile_settings'
                                ]
                              }
                            </Button>
                          </Link>
                        ) : (
                          <Button
                            smallGreen
                            onClick={this.handleClickToShowOriginalList}
                          >
                            {
                              strings[
                                'page.lists.notification.btn.show_original_list'
                              ]
                            }
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="wrap wrap--mobile">
                {listItems && listItems.length > 0 ? (
                  <Fragment>
                    <div className="mylist-content">
                      <List withFilter>
                        {listItems.map(item => (
                          <VideoItemAdapter
                            key={item.id}
                            video={item.content}
                            listId={item.list_id}
                            videoId={item.id}
                          />
                        ))}
                      </List>
                    </div>

                    <Pagination
                      page={search.page}
                      perPage={PER_PAGE}
                      count={totalCount}
                    />
                  </Fragment>
                ) : (
                  <div className="nf">
                    <span className="nf__subtitle">
                      {strings['page.my_lists.no_video']}
                    </span>
                    <span className="nf__text">
                      {`${strings['page.my_lists.empty.left']} "${data.title}" ${strings['page.my_lists.empty.right']}`}
                    </span>
                    <span className="nf__text">
                      <Link to={videosList.getPath()}>
                        {strings['page.my_lists.explore']}
                      </Link>
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </Fragment>
    )
  }
}

export default page(
  DI(['user', 'notifications', 'common'])(withRouter(ListView)),
  {
    hasRestrictedContent: true,
  }
)
