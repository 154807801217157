import React, { useCallback } from 'react'
import Item from '../item'
import { DI } from 'modules'
import qp from 'query-params-helpers'
import { cyrillicTitle } from 'helpers/strings'
import * as routes from 'config/routes'
import { videoView } from 'config/routes'

function VideoItemAdapter({
  video,
  view,
  common,
  imageSize,
  slideshowEnabled = true,
  isRemove,
  listId,
  itemId,
  videoId,
  videos,
  actions,
  modificator,
  setCurrentVideo,
}) {
  const { search_key } = videos.searchKey.payload
  const title = cyrillicTitle(video.title)

  imageSize = imageSize || (common.isMobile ? 'small' : 'medium')

  const onClick = useCallback(
    ev => {
      if (search_key) {
        actions.videos.sendStatsHit(search_key, video.external_name, 'click')
      }
    },
    [search_key]
  )

  let link = videoView.getRawPath(
    common.locale,
    `${video.external_name}/${encodeURIComponent(title)}`
  )

  link += qp.stringify(videos.searchKey.payload)

  return (
    <Item
      key={video.id}
      title={video.title}
      image={video.selected_preview_image[imageSize].url}
      link={link}
      time={video.published_at}
      aggregatedLimitType={video.aggregated_limit_type}
      duration={video.duration}
      //   itemRegion={video.region_name}
      //   itemCountry={video.country_name}
      //   itemLocation={video.location}
      tags={video.tags}
      isPool={video.limit_type === 'pool'}
      isFree={video.free_access}
      isSpherical={video.format === 'spherical'}
      slides={slideshowEnabled ? video.animated_preview_thumbnails : []}
      isRemove={isRemove}
      listId={listId}
      videoId={video.inner_id || videoId}
      itemId={itemId}
      externalName={video.id || video.external_name}
      view={view}
      tagLink={tag =>
        routes.videosFilter.getPath(
          common.locale,
          tag.state === 'regular' ? 'tag' : 'category',
          `${tag.id}-${tag.name}`
        )
      }
      onClick={onClick}
      modificator={modificator}
      setCurrentVideo={setCurrentVideo}
    />
  )
}

export default DI(['videos'])(VideoItemAdapter)
