import React, { Fragment } from 'react'
import { DateTime } from 'luxon'
import DayPicker from 'react-day-picker'

import { DI } from 'modules'
import Component from 'components/component'
import Outer from 'components/outer-click'
import DatepickerCaption from './datepicker-caption'

export class DateFilter extends Component {
  static defaultProps = {
    start: {},
    end: {},
  }

  state = {
    startMonth: new Date(),
    endMonth: new Date(),
    startOpen: false,
    endOpen: false,
  }

  disabledDays = {
    start: (day) => {
      if (this.state.end) {
        const startDate = DateTime.fromJSDate(day).toISO()

        return DateTime.fromISO(startDate) > DateTime.fromISO(this.state.end)
      }
    },
    end: (day) => {
      if (this.state.start) {
        const endDate = DateTime.fromJSDate(day).toISO()

        return DateTime.fromISO(endDate) < DateTime.fromISO(this.state.start)
      }
    },
  }

  constructor(props) {
    super(props)

    this.start = React.createRef(null)
    this.end = React.createRef(null)
  }

  componentDidMount() {
    const nextState = {}

    if (this.props.start?.value) {
      nextState.start = DateTime.fromISO(
        decodeURIComponent(this.props.start.value)
      )
    }

    if (this.props.end?.value) {
      nextState.end = DateTime.fromISO(decodeURIComponent(this.props.end.value))
    }

    if (Object.keys(nextState).length > 0) {
      this.setState(nextState)
    }
  }

  componentDidUpdate(prevProps) {
    let nextState

    if (this.props.start.value !== prevProps.start.value) {
      nextState = nextState || {}
      nextState.start = this.props.start.value
        ? DateTime.fromISO(decodeURIComponent(this.props.start.value))
        : null
    }

    if (this.props.end.value !== prevProps.end.value) {
      nextState = nextState || {}
      nextState.end = this.props.end.value
        ? DateTime.fromISO(decodeURIComponent(this.props.end.value))
        : null
    }

    if (nextState) {
      this.setState(nextState)
    }
  }

  onSelectDay =
    (prop) =>
    (day, { selected }) => {
      this.setState(
        {
          [prop]: DateTime.fromISO(day.toISOString()),
          [`${prop}Open`]: false,
        },
        () => {
          const event = new Event('input', {
            bubbles: true,
          })
          this[prop].current.dispatchEvent(event)
        }
      )
    }

  open = (prop) => (ev) => {
    this.setState((state) => ({
      [prop]: true,
    }))
  }

  close = (prop) => (ev) => {
    this.state[prop] &&
      this.setState({
        [prop]: false,
      })
  }

  onClickReset = (ev) => {
    const { common, actions, start, end } = this.props
    ev.preventDefault()
    const params = {
      ...common.searchParams,
    }
    const startDate = parseQ(start.name)
    const endDate = parseQ(end.name)

    delete params.q[startDate]
    delete params.q[endDate]
    delete params.page

    actions.common.updateSearchParams(params, common.isMobile)
  }

  setMonth = (prop, month) => {
    this.setState({
      [prop + 'Month']: month,
    })
  }

  renderPicker(prop) {
    return (
      <DayPicker
        locale={this.props.common.locale}
        month={this.state[prop + 'Month']}
        onDayClick={this.onSelectDay(prop)}
        selectedDays={this.state[prop] ? this.state[prop] : null}
        disabledDays={this.disabledDays[prop]}
        captionElement={({ date, localeUtils }) => (
          <DatepickerCaption
            date={date}
            localeUtils={localeUtils}
            onChange={(month) => {
              this.setState({
                [prop + 'Month']: month,
              })
            }}
          />
        )}
      />
    )
  }

  get isMobile() {
    const userAgent = window.navigator.userAgent
    const isMobile = userAgent.match(/(iPhone|iPod|iPad|Android|BlackBerry)/)
    return isMobile
  }

  renderColumn(prop) {
    const isStart = prop === 'start'
    const valueFormat = `yyyy-MM-dd'T'` + (isStart ? '00:00:00' : '23:59:59')
    const date = this.state[prop]
    const display = date
      ? date.setLocale(this.props.common.locale).toFormat('dd.MM.yy')
      : ''
    const value = date ? date.setLocale('en').toFormat(valueFormat) : ''

    if (this.isMobile) {
      return (
        <NativeDatePicker
          value={value}
          prop={prop}
          name={this.props[prop].name}
          // onSelect={this.onNativeCalendarSelect(prop)}
          setMonth={this.setMonth}
        />
      )
    } else {
      return (
        <Outer on={this.close(`${prop}Open`)}>
          <div className="date-column">
            <input
              type="text"
              className="date__input"
              name={this.props[prop].name}
              value={value}
              id={prop}
              ref={this[prop]}
              readOnly
            />
            <span className="date__text">{display}</span>
            <button
              type="button"
              className="date__btn"
              onClick={this.open(`${prop}Open`)}
            />
            {this.state[`${prop}Open`] && this.renderPicker(prop)}
          </div>
        </Outer>
      )
    }
  }

  render() {
    const { strings } = this.props.common
    return (
      <Fragment>
        <div className="date">
          {this.renderColumn('start')}
          {this.renderColumn('end')}
        </div>
        <div className="vod-filter-block-reset">
          <a
            href="#"
            className={this.classList(
              'text-button-reset',
              !(this.state.start || this.state.end) && '_disabled'
            )}
            onClick={this.onClickReset}
          >
            {strings['vods.filters.date.reset']}
          </a>
        </div>
      </Fragment>
    )
  }
}

function parseQ(column) {
  return column.slice(2, column.length - 1)
}

export function NativeDatePicker(props) {
  return (
    <div className="date-column">
      <input
        name={props.name}
        type="date"
        id={props.prop}
        className="date__text"
        value={(props.value || '').replace(/T.*/, '')}
        onChange={(ev) => {
          props.setMonth(props.prop)
        }}
      />
    </div>
  )
}

export default DI(['common'])(DateFilter)
