import Component from 'components/component'
import { Link } from 'components/link'
// import DEPRECATED_moment from 'moment';
import { DateTime } from 'luxon'
// import PropTypes from 'prop-types';
import { DI } from 'modules'
import React from 'react'
import AcceptAgreement from './accept-agreement'
import PlanFooter from './plan-card-footer'
import PlanCardGroup from './plan-card-group'

import 'submodules/responsivestyles/src/less/about.less'

export class PlanCard extends Component {
  static propTypes = {}

  static defaultProps = {}

  componentDidMount() {
    if (
      this.props.isModal &&
      (this.props.data.agreement_type === 'circle_branded' ||
        this.props.data.agreement_type === 'circle_onscreen_credits')
    ) {
      this.props.actions.tos.getToSAgreement(this.props.data.id)
    }
  }

  renderBody = (plan, status, strings) => {
    if (
      this.props.isModal &&
      (plan.agreement_type === 'circle_branded' ||
        plan.agreement_type === 'circle_onscreen_credits')
    ) {
      const tosInfo = this.props.tos.tosAgreementInfo.payload

      return (
        <div className="about-container">
          <div
            dangerouslySetInnerHTML={{
              __html: tosInfo.content,
            }}
            className="circle__branded"
          />

          <div class="plan-accept">
            <AcceptAgreement
              id={plan.id}
              type={plan.agreement_type}
              onAccept={this.props.onAccept}
              acceptOnly={this.props.acceptOnly}
            />
          </div>
        </div>
      )
    }

    return (
      <div
        className={this.classList(
          'plan-details__body',
          status.inactive && '_deactivated'
        )}
      >
        {!status.unapproved && (
          <div className="plan-details__title">
            {strings[`page.plans.title.${plan.agreement_type}`]}
          </div>
        )}
        {this.props.children}
        {status.user_accepting &&
          plan.agreement_type !== 'circle_branded' &&
          plan.agreement_type !== 'circle_onscreen_credits' && (
            <PlanCardGroup
              text={
                <AcceptAgreement
                  id={plan.id}
                  type={plan.agreement_type}
                  onAccept={this.props.onAccept}
                  acceptOnly={this.props.acceptOnly}
                />
              }
            />
          )}

        {status.payment_required && (
          <PlanCardGroup
            text={
              <Link
                className="button button--green"
                to={`/payment/subscription?plan=pass-${
                  plan.billing_period === 'month' ? 'monthly' : 'annually'
                }-eur&agreement_id=${plan.id}`}
              >
                {strings['page.plan_details.agreements.proceed']}
              </Link>
            }
          />
        )}
      </div>
    )
  }

  render() {
    const plan = this.props.data
    const { strings } = this.props.common
    const status = {
      [plan.status]: true,
    }

    if (this.props.isModal) return this.renderBody(plan, status, strings)

    return (
      <div className="plan-details">
        {status.created && (
          <Header
            status="process"
            text={strings['page.plan_details.requests.pass.processing.title']}
            description={
              strings['page.plan_details.requests.pass.processing.text']
            }
          />
        )}
        {status.payment_required && (
          <Header
            status="process"
            text={strings['page.plan_details.agreements.payment_required']}
          />
        )}
        {status.inactive && (
          <Header
            status="deactivated"
            text={strings['page.plan_details.agreements.deactivated']}
          />
        )}
        {status.user_accepting && (
          <Header
            status="new"
            text={strings['page.plan_details.unaccepted.title']}
          />
        )}
        {status.unapproved && (
          <Header
            status="deactivated"
            text={strings['page.plan_details.requests.pass.unapproved.title']}
          />
        )}
        {status.expired && (
          <Header
            status="expired"
            text={strings['page.plans.header.expired']}
            description={
              strings['page.plans.header.expired_you'] +
              strings[`page.plans.title.${plan.agreement_type}`] +
              strings['page.plans.header.expired_on'] +
              DateTime.fromISO(plan.expires_at)
                .toUTC()
                .toFormat(strings['page.plans.header.expired_format'])
            }
          />
        )}
        {this.renderBody(plan, status, strings)}
        <PlanFooter />
      </div>
    )
  }
}

function Header(props) {
  return (
    <div className="plan-details__header plan-details-status">
      <div
        className={`plan-details-status__name plan-details-status__name--${props.status}`}
      >
        {props.text}
      </div>
      {props.description && (
        <div className="plan-details-status__description">
          {props.description}
        </div>
      )}
    </div>
  )
}

export default DI(['tos'])(PlanCard)
