import React from 'react'
import Tippy from '@tippy.js/react'

export default class Tooltip extends React.Component {
  static defaultProps = {
    animation: 'fade',
    arrow: false,
    placement: 'bottom-start',
  }

  render() {
    return <Tippy {...this.props} />
  }
}
