import React from 'react'
// import {Link} from 'react-router-dom';
import { DI } from 'modules'
import * as routes from 'config/routes'
import { Link } from 'components/link'

export class NotFoundLivePage extends React.Component {
  render() {
    const strings = this.props.common.strings

    return (
      <section className="content">
        <div className="iwrap iwrap not-found-page">
          <div className="page-title">
            {strings['page.lives.not_found.title']}
          </div>
          <div className="not-found-text">
            <p>
              {strings['page.lives.not_found.text_1']}{' '}
              <Link to={routes.livesList.getPath()}>
                {strings['page.lives.not_found.link']}
              </Link>
              {strings['page.lives.not_found.text_2']}
            </p>
          </div>
        </div>
      </section>
    )
  }
}

export default DI()(NotFoundLivePage)
