// import DEPRECATED_moment from 'moment-timezone';

import { DateTime } from 'luxon'

export const formatDuration = duration =>
  duration
    ? DateTime.fromMillis(duration, { zone: 'Etc/UTC' })
        .toFormat('HH:mm:ss')
        .replace(/^00:/, '')
    : 0
