import React from 'react'
import Component from 'components/component'
import { Link } from 'react-router-dom'

import { ListStatus, ListStatusMobile } from 'pages/content/lives/live-status'
import { GridTime as VideoTime } from './video-time'
import Preview from './preview'
import { LazyLoadComponent } from 'react-lazy-load-image-component'
import GCMark from 'components/gc-mark'

export default class LCItem extends Component {
  static displayName = 'LCItem'
  state = {}

  constructor(props) {
    super(props)
  }

  setSlideshowMode = slideshowMode => ev => {
    this.setState({ slideshowMode })
  }

  onMouseMove = ev => {
    let { slides } = this.props,
      rect = ev.target.getBoundingClientRect()

    let slide = Math.round(
      ((ev.clientX - rect.left) / rect.width) * slides.length
    )

    this.setState({ slide })
  }

  render() {
    const {
      image,
      link,
      isSpherical,
      preview,
      isFree,
      isPool,
      isOnLive,
      title,
      isLive,
      isRemove,
      time,
      status,
      tbc,
      tags,
      place,
      buttons,
      removeButton,
      hover,
      slides,
      tagLink,
      lockedForUser,
      isArchive,
      isPremiumArchive,
      strings,
      cuttingAvailable,
    } = this.props

    const slide = slides[this.state.slide] || image
    const previewImage = this.state.slideshowMode ? slide : image

    return (
      <div className="lc">
        <div className="lc-side">
          <div
            className="lc-img"
            onMouseMove={slides.length ? this.onMouseMove : void 0}
            onMouseEnter={this.setSlideshowMode(true)}
            onMouseLeave={this.setSlideshowMode(false)}
          >
            <LazyLoadComponent
              placeholder={
                <div className="lc__img _loading">
                  <div className="_img-loader"></div>
                </div>
              }
            >
              <Preview
                image={previewImage}
                link={link}
                hover={hover}
                preview={preview}
                title={title}
                className={'lc__img'}
                sliderMode={this.state.slideshowMode}
                onClick={this.props.onClick}
              />
            </LazyLoadComponent>
            <GCMark
              isLocked={lockedForUser}
              titleList={[
                isFree && strings['vods.filters.type.free'],
                isPool && strings['vods.filters.type.pool'],
                // isArchive && strings['vods.filters.type.archive'],
                // isPremiumArchive && strings['vods.filters.type.premium_archive']
              ]}
              text={[
                isFree && this.props.strings['project_card.bage.free.text'],
                isPool && this.props.strings['project_card.bage.pool.text'],
              ]}
              open={isFree || isPool ? hover : void 0}
              classNamePrefix="lc"
            />
            {isSpherical && <span className="gc__360" />}
            {!!buttons.length && (
              <div className="lc-buttons">
                {buttons.map(button => (
                  <button
                    key={button.text}
                    type="button"
                    className="lc__button"
                    onClick={button.callback}
                  >
                    {button.text}
                  </button>
                ))}
              </div>
            )}
          </div>
          {isLive && (
            <Link to={link} className="lc-helper">
              <ListStatusMobile
                cuttingAvailable={cuttingAvailable}
                time={time}
                status={status}
                tbc={tbc}
              />
            </Link>
          )}
        </div>
        <div className="lc-content">
          <Link to={link} className="lc__link" onClick={this.props.onClick} />
          <span className="lc__title">{title}</span>
          {isLive ? (
            <ListStatus
              cuttingAvailable={cuttingAvailable}
              time={time}
              status={status}
              tbc={tbc}
              isOnLive={isOnLive}
            />
          ) : (
            <VideoTime time={time} />
          )}
          <div className="lc-place">
            <Link to={link} className="lc__place" onClick={this.props.onClick}>
              {place}
            </Link>
          </div>
          <div className="lc-tags">
            {tags.map(tag => (
              <Link key={tag.id} to={tagLink(tag)} className="lc__tag">
                {tag.name}
              </Link>
            ))}
          </div>
          {!!isRemove &&
            removeButton.map(el => (
              <button
                key={el.text}
                type="button"
                className="btn-bord lc__remove"
                onClick={el.callback}
              >
                {el.text}
              </button>
            ))}
        </div>
      </div>
    )
  }
}
