import React from 'react'
import { DI } from 'modules'
import Component from 'components/component'
import Form from 'common/components/form/new-form'
import { TextInput } from 'components/form/text-input'
import { Modal } from 'components/modal'
import VerifyModal from './verify'

export class MyListModal extends Component {
  static defaultProps = {
    close() {},
    confirm() {},
  }

  state = {
    title: this.props.value || '',
    chars: this.props.value ? this.props.value.length : 0,
  }

  onReset = ev => {
    this.setState({
      title: '',
    })
    this.props.close()
  }

  onChange = (ev, form) => {
    this.setState(form)
  }

  onSubmit = (ev, form) => {
    if (form.title) {
      this.props.confirm(form.title)
    } else this.props.confirm(this.props.placeholder)
  }
  charCounter = () => {
    const charCount = event.target.value.length
    this.setState({ chars: charCount })
  }

  render() {
    const { strings } = this.props.common
    return (
      <Modal>
        <Form
          onChange={this.onChange}
          onReset={this.onReset}
          onSubmit={this.onSubmit}
        >
          <div className="popup-with-ico">
            <span className="popup__title">{this.props.title}</span>
            <div className="popup-field">
              <TextInput
                isRequired
                maxLength={64}
                placeholder={this.props.placeholder}
                name="title"
                value={this.state.title}
                onChange={this.charCounter}
              />
              <span class="popup-field__counter">{`${this.state.chars}/64`}</span>
            </div>
            <div className="popup-buttons popup-buttons--ico">
              <div className="popup-button">
                <button type="reset" className="text-button">
                  {strings['page.members.invite_modal.cancel']}
                </button>
              </div>
              <div className="popup-button">
                <button type="submit" className="button">
                  {this.props.buttonText
                    ? this.props.buttonText
                    : strings['page.my_lists.list_modal.confirm']}
                </button>
              </div>
            </div>
          </div>
        </Form>
      </Modal>
    )
  }
}

export { VerifyModal }
export default DI(['user', 'notifications'])(MyListModal)
