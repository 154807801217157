import React from 'react'
import Component from 'components/component'
import Slider from 'components/slider'
import { VideoItemAdapter } from 'components/list'

const breakpoints = [
  {
    width: 9500,
    count: 20,
  },
  {
    width: 9000,
    count: 19,
  },
  {
    width: 8500,
    count: 18,
  },
  {
    width: 8000,
    count: 17,
  },
  {
    width: 7500,
    count: 16,
  },
  {
    width: 7000,
    count: 15,
  },
  {
    width: 6500,
    count: 14,
  },
  {
    width: 6000,
    count: 13,
  },
  {
    width: 5500,
    count: 12,
  },
  {
    width: 5000,
    count: 11,
  },
  {
    width: 4500,
    count: 10,
  },
  {
    width: 4000,
    count: 9,
  },
  {
    width: 3500,
    count: 8,
  },
  {
    width: 3000,
    count: 7,
  },
  {
    width: 2500,
    count: 6,
  },
  {
    width: 1900,
    count: 5,
  },
  {
    width: 1340,
    count: 4,
  },
  {
    width: 1024,
    count: 3,
  },
  {
    width: 700,
    count: 2,
  },
]

export default class DashboardSlider extends Component {
  render() {
    return (
      <Slider
        items={this.props.items}
        classNamePrefix="dashboard-slider"
        debug={this.props.debug}
        breakpoints={breakpoints}
        renderItem={(item, index) => (
          <VideoItemAdapter
            key={`${index}-${item.id}`}
            video={item}
            view="dbs"
            setCurrentVideo={this.props.setCurrentVideo}
          />
        )}
      />
    )
  }
}
