import React, { useState } from 'react'
import Component from 'components/component'

export default function FilterBlock(props) {
  const { title, children, openDefault = false } = props
  const [open, setOpen] = useState(openDefault)

  const headClassName = Component.classList(
    'vod-filter-block-head',
    open && '_opened'
  )

  return (
    <div className="vod-filter-block">
      <div className={headClassName} onClick={() => setOpen(!open)}>
        <span className="vod-filter-block__title">{title}</span>
      </div>
      <div
        className="vod-filter-block-body"
        style={{ display: open ? 'block' : 'none' }}
      >
        {children}
      </div>
    </div>
  )
}
